import Cookies from 'js-cookie';
import cookie from 'cookie';

const AuthTokens = {
  __session: null as string | null,
  async getAndStashFromContext(context: any) {
    const cookies = cookie.parse(
      context.req ? context.req.headers.cookie || '' : '',
    );
    if (cookies.__session) {
      AuthTokens.__session = cookies.__session;
      return cookies.__session;
    }
    return null;
  },
  async get() {
    if (AuthTokens.__session) return AuthTokens.__session;
    return Cookies.get('__session');
  },
  async set(token: string) {
    AuthTokens.__session = token;
    return Cookies.set('__session', token);
  },
  async clear() {
    AuthTokens.__session = null;
    return Cookies.remove('__session');
  },
};

export default AuthTokens;

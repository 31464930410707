import get from 'lodash/get';

import { ImageDimensions, ImageCrop } from 'lib/cms/types';

export default (
  imageDimensions?: ImageDimensions,
  imageCrop?: ImageCrop,
): number => {
  return (
    ((get(imageDimensions, 'height', 0) *
      (1 - get(imageCrop, 'bottom', 0) - get(imageCrop, 'top', 0))) /
      (get(imageDimensions, 'width', 0) *
        (1 - get(imageCrop, 'left', 0) - get(imageCrop, 'right', 0)))) *
    100
  );
};

import React from 'react';
import Icon from 'elements/Icon';
import { useAccordion } from './AccordionContext';

/**
 * Props for <AccordionItemHeader>.
 * Props are automatically passed to the component from <AccordionItem>.
 */
export type AccordionItemHeaderProps = {
  index?: number;
  activeClassName?: string;
  isOpen?: boolean;
  children: any;
};

/**
 * Accordion -> AccordionItem -> AccordionItemHeader
 * @param index Passed automatically by <AccordionItem />.
 * @param activeClassName Passed manually. Sets the className of the body when active.
 * @param isOpen Passed automatically by <AccordionItem />.
 * @param children Any valid React children.
 * @returns The body of the accordion item.
 */
const AccordionItemHeader: React.FC<AccordionItemHeaderProps> = ({
  index,
  activeClassName = '',
  children,
  isOpen,
}) => {
  const { toggleAccordionItem } = useAccordion();
  const toggleItem = () => toggleAccordionItem(index!);

  return (
    <div role="heading" aria-level={3}>
      <button
        onClick={toggleItem}
        role="button"
        aria-expanded={isOpen}
        aria-controls={`AccordionItemBody-${index}`}
        tabIndex={0}
        className={`flex items-center font-sans-serif text-sans-serif-30px mb-2 ${
          isOpen && activeClassName
        }`}
      >
        <Icon
          icon="chevronDown"
          className={`text-black mr-3 transform transition-transform ease-easy-ease-out ${
            isOpen ? 'rotate-180' : 'rotate-0'
          }`}
        />
        <div id={`AccordionItemHeader-${index}`} className="flex items-center">
          {children}
        </div>
      </button>
    </div>
  );
};

export default AccordionItemHeader;

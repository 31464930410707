import Language from 'constants/Language';
import Image from 'next/image';
import Link from 'next/link';

type GridItemProps = {
  // TODO: Properly type this.
  product: any;
};

export const GridItem: React.FC<GridItemProps> = ({ product }) => {
  if (!product || !product.variant) return null;

  // TODO: Implement style-first-cell-enlarged from nunu.

  // TODO: Make this compatible with both order line items
  // and course documents from Sanity.

  return (
    <Link
      href="/course/intermediate-coding-for-designers"
      passHref
      aria-label={Language.product.gridItem.ariaLabel}
    >
      <a className="ProductGridItem font-serif bg-black text-white text-decoration-none overflow-hidden hover:rounded-lg relative p-5 transition-all">
        <div className="ProductGridItem__inner-container bg-black h-full flex flex-col justify-between">
          <span className="flex flex-col uppercase">
            <div className="flex justify-between text-serif-18px font-light">
              <span className="ProductGridItem__title">{product.title}</span>
              <span className="ProductGridItem__price text-right pl-6 uppercase">
                {Language.product.gridItem.price({
                  price: parseInt(product.originalTotalPrice.amount),
                })}
              </span>
            </div>
          </span>
          <div className="ProductGridItem__image-container h-full w-full flex items-center justify-center relative">
            <Image
              alt={Language.product.gridItem.image.alt({
                productName: product.title,
              })}
              layout="fill"
              objectFit="contain"
              className="ProductGridItem__image w-full h-full fit-contain xs:py-4 events-none"
              src={product.variant.image?.originalSrc}
            />
          </div>
          <div className="ProductGridItem__details font-sans-serif text-sans-serif-12px uppercase flex justify-between w-100">
            <span className="flex items-center">
              <span className="w-3 h-3 bg-white rounded-xl mr-1"></span>
              <span className="bulleted">
                {product.variant.product.productType}
              </span>
            </span>
          </div>
        </div>
      </a>
    </Link>
  );
};

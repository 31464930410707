import * as z from 'zod';

export type ProfileData = z.TypeOf<typeof ProfileData>;
export const ProfileData = z.object({
  name: z.string().optional(),
  bio: z.string().optional(),
  links: z.array(z.string()).max(3).optional(),
  pronouns: z.string().optional(),
  birthdate: z.string().optional(),
});

export type PublicUser = z.TypeOf<typeof PublicUser>;
export const PublicUser = z.object({
  id: z.string(),
  handle: z.string().min(3),
  data: ProfileData,
  createdAt: z.string().nullable(),
});

export type User = z.TypeOf<typeof User>;
export const User = PublicUser.and(
  z.object({
    email: z.string(),
    createdAt: z.string(),
  }),
);

export type Token = z.TypeOf<typeof Token>;
export const Token = z.object({
  token: z.string(),
});

export type ExistsPlatforms = z.TypeOf<typeof ExistsPlatforms>;
export const ExistsPlatforms = z.union([
  z.literal('shopify'),
  z.literal('shopify_activate'),
  z.literal('shopify_declined'),
  z.literal('local'),
  z.literal('none'),
]);

export type Exists = z.TypeOf<typeof Exists>;
export const Exists = z.object({
  exists: ExistsPlatforms,
});

export type ServerError = z.TypeOf<typeof ServerError>;
export const ServerError = z.object({
  title: z.string(),
  detail: z.string(),
  code: z.string(),
  status: z.string(),
});

import { IconType } from 'elements/types';
import pluralize from 'pluralize';
import { DatePartsType, LessonType } from 'types';

interface ConstantString {
  [x: string]: string | number;
}

const Language = {
  errorPage: {
    message: 'Page not found.',
  },
  seo: {
    description: 'Index Space',
    siteName: 'Index Space',
    shareCard:
      'https://cdn.sanity.io/images/tpu3b8ny/playground/75f9ca2db56731fa7b963a20381640c9e4cf7dd6-1200x660.jpg',
    handle: '@index_space_org',
    title: ({ title }: { title: string }) => `Index Space - ${title}`,
  },
  carousel: {
    nextButtonAriaLabel: 'Go to next slide.',
    prevButtonAriaLabel: 'Go to previous slide.',
  },
  profile: {
    navigationTitle: 'Profile navigation',
    editProfile: 'Edit profile',
    signOut: 'Sign out',
    resetPassword: 'Reset password',
    saveChanges: 'Save changes',
    discardChanges: 'Discard changes',
    yourCourses: {
      title: 'Your courses',
    },
    bioSection: {
      textArea: {
        ariaLabel: 'Your profile bio',
        placeholder: 'What are you interested in learning about?',
      },
      title: ({ userName }: ConstantString) =>
        `${userName} is interested in learning about...`,
      emptyState: ({ userName }: ConstantString) =>
        `${userName} hasn’t written what they’re interested in learning about yet.`,
    },
    purchaseHistory: {
      title: 'Your purchase history',
      emptyState: "You haven't made any purchases yet.",
    },
    avatarSection: {
      imageAlt: ({ name }: ConstantString) => `Avatar image for ${name}`,
      joinedOn: ({ joinDate }: ConstantString) => `Joined ${joinDate}`,
      handle: (handle: string) => `@${handle}`,
      nameField: {
        placeholder: 'Your first name…',
        ariaLabel: 'Add your first name',
      },
      handleField: {
        placeholder: 'Add a username…',
        ariaLabel: 'Add a username to your profile',
      },
    },
    pronouns: {
      imageAlt: 'An icon representing personal pronouns',
      placeholder: 'Share your pronouns…',
    },
    birthdate: {
      imageAlt: 'An icon representing a birthday cake',
      ariaLabel: 'Enter your birth date',
      bornOn: ({ birthdate }: ConstantString) => {
        let date = new Date(birthdate);
        let normalizedDateString = new Date(
          date.getTime() + date.getTimezoneOffset() * 60000, // Timezone adjustment
        ).toLocaleDateString('en-US', {
          month: 'long',
          day: 'numeric',
          year: 'numeric',
        });

        return `Born on ${normalizedDateString}`;
      },
    },
    profileLinks: {
      imageAlt: 'An icon representing a link',
      linkAriaLabel: ({ plainUrl }: ConstantString) =>
        `Visit your profile link to ${plainUrl}`,
      input: {
        ariaLabel: ({ linkIndex, quantityOfLinks }: ConstantString) =>
          `Edit your website links. You are editing link number ${linkIndex} of ${quantityOfLinks}`,
        placeholder: 'Add a website or social media link to your profile',
      },
    },
    orderRowItem: {
      orderNumber: {
        eyebrow: ({ date, quantity }: { date: string; quantity: number }) =>
          `${date} • ${quantity} ${pluralize('item', quantity)}`,
        orderNumber: ({ orderNumber }: ConstantString) =>
          `Order #${orderNumber}`,
      },
      orderTotal: {
        eyebrow: 'Total',
        value: ({ price }: ConstantString) => `$${price}`,
      },
      orderStatus: {
        orderShipped: 'Order shipped',
        viewTrackingLink: {
          label: 'View tracking',
          ariaLabel: ({ orderNumber }: ConstantString) =>
            `View tracking for order#${orderNumber}`,
        },
        viewStatus: 'View order status',
      },
      productImage: {
        alt: ({ productName }: ConstantString) =>
          `An image representing ${productName}`,
      },
      linkImage: {
        alt: 'An icon representing a link',
      },
      button: {
        ariaLabel: ({ orderNumber }: ConstantString) =>
          `order#${orderNumber} details`,
      },
    },
  },
  product: {
    gridItem: {
      ariaLabel: 'Navigate to this product',
      price: ({ price }: ConstantString) => `$${price}`,
      image: {
        alt: ({ productName }: ConstantString) =>
          `An image representing ${productName}`,
      },
    },
  },
  sheets: {
    container: {
      close: 'Close',
      closeIconAlt: 'An icon depicting a smiley face',
    },
    orderDetails: {
      orderNumber: ({ number }: ConstantString) => `Order #${number}`,
      orderedDate: ({ date }: ConstantString) => `Ordered on ${date}`,
      shippingInformation: {
        title: 'Shipping Information',
        trackPackage: {
          text: 'Track package',
          ariaLabel: 'Tracking link',
        },
      },
      billing: {
        title: 'Billing',
        subtotal: 'Subtotal',
        shipping: 'Shipping',
        total: 'Total',
        tax: 'Tax',
      },
      items: {
        title: 'Items',
      },
    },
  },
  auth: {
    primaryButtonDefaultLabel: 'Next',
    secondaryButtonDefaultLabel: 'Previous',
    loadingLabel: 'Loading...',
    viewPasswordLabel: 'View password',
    errors: {
      emailRequired: 'Email is required.',
      nameRequired: 'Name is required.',
      passwordRequired: 'Password is required.',
      birthdateRequired: 'Birthdate is required.',
      handleRequired: 'Username is required.',
      passwordLength: 'Password needs to be greater than 7 characters.',
      wrongPassword: "You've entered an incorrect password!",
      invalidBirthdate: 'Please enter a date in the past.',
      generalSignIn: 'There was an error while signing in. Please try again.',
      generalSignUp:
        'There was an error creating your profile. Please try again.',
      generalEmailCheck:
        'There was an error while checking your email. Please try again.',
      general: 'Error encountered',
      generalResetPassword:
        'There was an error resetting your password. Please try again.',
      generalUpdatePassword:
        'There was an error updating your password. Please try again.',
    },
    emailPanel: {
      header: 'Welcome to Index! Let’s get you signed in.',
      content: 'My email address is',
      emailInput: {
        placeholder: 'Email address',
        ariaLabel: 'Email address',
      },
      activateAccountEmailMessage:
        'We’ve sent you a confirmation email. Please follow the link within the email to continue!',
      declinedStateMessage:
        'This account is in an error state. Please contact info@index-space.org',
    },
    passwordPanel: {
      header: 'Welcome back!',
      content: ['My password is', 'Forgot your password? No worries!'],
      passwordInput: {
        placeholder: 'Your password',
        ariaLabel: 'Password',
      },
      resetPasswordButtonLabel: 'Reset password',
      resetPasswordSuccessMessage: ({ email }: ConstantString) =>
        `An email was sent to ${email} to reset your password.`,
    },
    profilePanel: {
      header: 'You don’t have a profile yet! Let’s create one together.',
      content: [
        'My name is',
        'and my password will be',
        'I want my username to be',
      ],
      nameInput: {
        placeholder: 'Your name',
        ariaLabel: 'Name',
      },
      passwordInput: {
        placeholder: 'Your password',
        ariaLabel: 'Password',
      },
      handleInput: {
        placeholder: 'Enter a username',
        ariaLabel: 'Username',
      },
    },
    generalPanel: {
      content: ['My pronouns are', 'and I was born on'],
      pronounsInput: {
        placeholder: 'Your Pronouns',
        ariaLabel: 'Pronouns',
      },
      dateInput: {
        placeholder: 'MM/DD/YYYY',
        ariaLabel: 'Date of birth',
      },
    },
    bioPanel: {
      content: 'I’m excited to learn about…',
      bioTextarea: {
        placeholder: 'What are you interested in learning about?',
        ariaLabel: 'What are you interested in learning about?',
      },
      primaryButtonLabel: 'Complete',
    },
    finalPanel: {
      content: {
        first: ({ name }: ConstantString) => `Okay ${name}, you're all set!`,
        second: 'Welcome to the Index community.',
      },
      primaryButtonLabel: 'Proceed to profile',
      secondaryButtonLabel: 'Join the Discord',
    },
    resetPasswordPanel: {
      header: "Hey, let's reset your password.",
      content: 'My new password will be',
      passwordInput: {
        placeholder: 'New password',
        ariaLabel: 'New password',
      },
      buttonLabel: 'Reset password',
      passwordResetButtonLabel: 'Sign in',
      passwordResetSuccessMessage: 'Your password has been updated.',
    },
  },
  global: {
    cookieModal: {
      src: '/images/cookie-consent.png',
      button: {
        label: 'Accept',
        ariaLabel: 'Accept cookie consent.',
      },
    },
    footer: {
      contact_links: {
        email: {
          text: 'Contact us',
          ariaLabel: (emailAddress: string) =>
            `Open your email client with a blank draft addressed to ${emailAddress}`,
          emailAddress: 'info@index-space.org',
        },
        instagram: {
          text: 'Instagram',
          ariaLabel: (href: string) => `Navigate to ${href}`,
          href: 'https://www.instagram.com/index_space/',
        },
        twitter: {
          text: 'Twitter',
          ariaLabel: (href: string) => `Navigate to ${href}`,
          href: 'https://twitter.com/index_space_org',
        },
      },
      newsletter_subscribe: {
        glyph: {
          default: '✐',
          submitting: '...',
        },
        placeholders: {
          default: 'SIGN UP FOR EMAILS',
          success: "You're in! Thanks!",
        },
      },
    },
    icons: (icon: IconType) => {
      switch (icon) {
        case 'menu':
          return { svgTitle: 'An icon representing a menu' };
        case 'close':
          return { svgTitle: 'An icon representing an x' };
        case 'chevronDown':
          return { svgTitle: 'An icon representing a chevron' };
        case 'file':
          return { svgTitle: 'An icon representing a file' };
        case 'cube':
          return { svgTitle: 'An icon representing a cube' };
        case 'birthday':
          return { svgTitle: 'An icon representing a birthday cake' };
        case 'camera':
          return { svgTitle: 'An icon representing a camera' };
        case 'downloadFile':
          return { svgTitle: 'An icon representing a downloadable file' };
        case 'eye':
          return { svgTitle: 'An icon representing an eye' };
        case 'spring':
          return {
            svgTitle: 'An icon representing an illustration of a spring',
          };
        case 'bounce':
          return {
            svgTitle:
              'An icon representing an illustration representing bounce',
          };
        case 'confused':
          return {
            svgTitle:
              'An icon representing an illustration representing confusion',
          };
        case 'fern':
          return {
            svgTitle:
              'An icon representing an illustration representing a fern',
          };
        case 'flower':
          return {
            svgTitle:
              'An icon representing an illustration representing a flower',
          };
        case 'greenThumbsUp':
          return { svgTitle: 'An icon representing a green thumbs up' };
        case 'heart':
          return { svgTitle: 'An icon representing a heart' };
        case 'link':
          return { svgTitle: 'An icon representing a link' };
        case 'pencil':
          return { svgTitle: 'An icon representing a pencil' };
        case 'pointerFinger':
          return { svgTitle: 'An icon representing a pointing finger' };
        case 'pronouns':
          return {
            svgTitle: 'An icon representing a person with a heart beside them',
          };
        case 'smile':
          return { svgTitle: 'An icon representing a smiley face' };
        case 'star1':
          return { svgTitle: 'An icon representing a star' };
        case 'star2':
          return { svgTitle: 'An icon representing a star' };
        case 'thumbsUp':
          return { svgTitle: 'An icon representing a thumbs up' };
        case 'video':
          return { svgTitle: 'An icon representing a video' };
        case 'web':
          return { svgTitle: 'An icon representing the world wide web' };
        default:
          return { svgTitle: 'An icon' };
      }
    },
    navigation: {
      title: 'Global site navigation',
      logo: {
        alphabet: 'abcfghjklmopqrstuvwyz',
        index: 'index',
      },
      links: [
        {
          text: 'Workshops',
          href: '/workshops',
          ariaLabel: 'Workshops',
        },
        {
          text: 'Events',
          href: '/Events',
          ariaLabel: 'Events',
        },
        {
          text: 'Books',
          href: '/profile',
          ariaLabel: 'Books',
        },
        {
          text: 'About',
          href: 'https://index-space.org/pages/about',
          ariaLabel: 'About',
        },
      ],
      profileLink: {
        ariaLabel: 'Navigate to your profile',
        image: {
          alt: 'Your profile image',
        },
      },
      mobileMenu: {
        controls: {
          open: {
            ariaLabel: 'Open website navigation menu',
          },
          close: {
            ariaLabel: 'Close website navigation menu',
          },
        },
      },
    },
  },
  contentRow: {
    ariaLabel: ({ label, isLink }: { label: string; isLink: boolean }) => {
      if (isLink) return `Navigate to ${label}`;
      else return `View ${label}`;
    },
    graphicAlt: (title: string) => `An image representing "${title}"`,
  },
  QuoteBlock: {
    quoteName: ({ name }: ConstantString) => ` — ${name}`,
    buttonAriaLabel: ({ name }: ConstantString) => ` Link to ${name}`,
  },
  course: {
    syllabus: 'Syllabus',
    introduction: 'Introduction',
    lesson: {
      eyebrow: (index: number) => `Lesson ${index}`,
    },
    footer: {
      firstLine: 'You’ve reached the end of',
      currentLesson: ({ index, title }: { index: number; title: string }) =>
        `Lesson ${index}: ${title}.`,
      nextLesson: ({ index, title }: { index: number; title: string }) =>
        `Next up is Lesson ${index}: ${title}.`,
      button: {
        text: 'Next Lesson',
        ariaLabel: (index: number) => `Continue to Lesson ${index}`,
      },
    },
  },
  resource: {
    menu: {
      title: 'Table of Contents',
    },
  },
  errors: {
    responses: {
      unauthorized: {
        title: 'Unauthorized',
      },
      unknown_shop: {
        title: 'Unknown Shop',
      },
      requires_access_token: {
        title: 'Requires Access Token',
      },
      not_found: {
        title: 'Not Found',
      },
      not_unique: {
        title: 'ActiveRecord::RecordNotUnique',
      },
    },
  },
  resources: {
    hero: {
      activityCard: {
        eyebrow: (lessonType: LessonType) => {
          switch (lessonType) {
            case 'live':
              return 'Class in Session';
            case 'upcoming':
              return 'Upcoming Session';
            case 'async':
              return 'Recently Viewed';
          }
        },
        title: (lessonNumber: string | number, title: string) =>
          `Lesson ${lessonNumber}: ${title}`,
        leftButton: {
          text: (lessonType: LessonType) => {
            switch (lessonType) {
              case 'live':
                return 'Join Lesson';
              case 'upcoming':
                return 'View Material`';
              case 'async':
                return 'View Material';
            }
          },
          ariaLabel: (lessonType: LessonType) => {
            switch (lessonType) {
              case 'live':
                return 'Open the lesson conference link';
              case 'upcoming':
                return 'Navigate to the upcoming lesson`';
              case 'async':
                return 'View recent lesson material';
            }
          },
        },
        rightButton: {
          text: (lessonType: LessonType, lessonNumber?: string | number) => {
            switch (lessonType) {
              case 'live':
                return 'View lesson';
              case 'upcoming':
                return `Review Lesson ${lessonNumber}`;
              case 'async':
                return 'Next Lesson';
            }
          },
          ariaLabel: (lessonType: LessonType) => {
            switch (lessonType) {
              case 'live':
                return 'Open the lesson material in a new tab';
              case 'upcoming':
                return 'Navigate to the upcoming lesson`';
              case 'async':
                return 'Navigate to the previous lesson';
            }
          },
        },
        clock: {
          title: 'Lesson watchface',
          daysLabel: 'Days',
          tempLabel: 'NYC',
          dateFormat: (nextSessionDateParts: DatePartsType) =>
            `${nextSessionDateParts.month}/${nextSessionDateParts.day}`,
          timeFormat: (nextSessionDateParts: DatePartsType) =>
            `${nextSessionDateParts.hour}:${nextSessionDateParts.minute}`,
          tempFormat: (temp: string) => `${temp}° F`,
        },
      },
    },
    menu: {
      title: 'Course navigation',
    },
  },
};

export default Language;

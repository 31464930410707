import z from 'zod';
import Sanity from './config';
import Queries from './queries';

import { FetchCourseArgs, Course, AllCourseVariantIds } from './types';

const handleSanityResponse = (parser: z.ZodTypeAny) => (response: unknown) => {
  try {
    if (process.env.NODE_ENV === 'development') {
      return parser.parse(response);
    }
    if (process.env.NODE_ENV === 'production') {
      return parser.parse(response);
    }
  } catch (err) {
    if (process.env.NODE_ENV === 'development') {
      console.error('Sanity response type mismatch', err, response);
    }
    if (process.env.NODE_ENV === 'production') {
      // TODO: Add sentry
      // Sentry.captureException(err);
      console.error('Sanity response type mismatch', err, response);
    }
    throw err;
  }
};

export const CmsClient: {
  fetchCourse(args: FetchCourseArgs): Promise<Course>;
  fetchAllCourseProductIds(): Promise<AllCourseVariantIds>;
} = {
  fetchCourse(args: FetchCourseArgs) {
    return Sanity.fetch(Queries.course(args)).then(
      handleSanityResponse(Course),
    );
  },
  fetchAllCourseProductIds() {
    return Sanity.fetch(Queries.allCourseProductIds()).then(
      handleSanityResponse(AllCourseVariantIds),
    );
  },
};

import { useRouter } from 'next/router';
import React, {
  createContext,
  Dispatch,
  useContext,
  useReducer,
  useCallback,
  useEffect,
  useRef,
} from 'react';
import {
  SheetActionType,
  SheetIntent,
  SheetState,
  CookieModalActionType,
  CookieModalIntent,
  CookieModalState,
} from 'types';
import localForage from 'localforage';

/**
 * This will be used to combine UI actions into a single type.
 * This is mainly to make it easier to maintain action types.
 * When adding a new set of Action types, this will become a
 * union, eg.: `type: SheetIntent | NavIntent | CartIntent`.
 */
type AllActionType = SheetActionType | CookieModalActionType;

type InitialStateType = {
  sheet: SheetState;
  cookieModal: CookieModalState;
};

const initialState = {
  sheet: {
    intent: null,
    data: null,
  },
  cookieModal: {
    isOpen: false,
  },
};

/**
 * UI Context is used to store state for global UI elements.
 * We rely on React's useReducer() hook to access and update the state.
 * This pattern works in a similar way as Redux.
 *
 * If you want to add a value to the context provider (UiProvider)
 * you should first define the types here.
 */
export const UiContext = createContext<{
  state: InitialStateType;
  dispatch: Dispatch<AllActionType>;
  registerSheetIntent: (value: SheetIntent, payload: any) => void;
  closeSheet: () => void;
  openCookieModal: () => void;
  closeCookieModal: () => void;
  cookieModal: React.Ref<HTMLDivElement>;
}>({
  state: initialState,
  dispatch: () => null,
  registerSheetIntent: () => {},
  closeSheet: () => {},
  openCookieModal: () => {},
  closeCookieModal: () => {},
  cookieModal: null,
});

/** Controls the global state of Sheets, including visibility and content. */
export const sheetReducer = (state: SheetState, action: SheetActionType) => {
  switch (action.type) {
    case 'SHOW_ORDER_DETAILS':
      return {
        intent: 'SHOW_ORDER_DETAILS',
        data: action.payload,
      };
    case 'SHOW_TABLE_OF_CONTENTS':
      return {
        intent: 'SHOW_TABLE_OF_CONTENTS',
        data: action.payload,
      };
    case 'CLOSE_SHEET':
      return {
        intent: null,
        data: null,
      };
    default:
      return state;
  }
};

export const cookieModalReducer = (
  state: CookieModalState,
  action: CookieModalActionType,
) => {
  switch (action.type) {
    case 'CLOSE_COOKIE_MODAL':
      localForage.setItem('cookieModalDismissDate', new Date(), (err) =>
        console.error(err),
      );
      return {
        ...state,
        isOpen: false,
      };

    case 'OPEN_COOKIE_MODAL':
      return {
        ...state,
        isOpen: true,
      };
  }
};

/**
 * This pattern has the same rationale as AllActionType.
 * mainReducer() combines multiple reducers, each controlling
 * a specific piece of state. This keeps things a little cleaner
 * in the UiProvider code.
 */
const mainReducer = (
  { sheet, cookieModal }: InitialStateType,
  action: AllActionType,
) => ({
  sheet: sheetReducer(sheet, action as SheetActionType) as SheetState,
  cookieModal: cookieModalReducer(
    cookieModal,
    action as CookieModalActionType,
  ) as CookieModalState,
});

export const UiProvider: React.FC = ({ children }) => {
  const [state, dispatch] = useReducer(mainReducer, initialState);

  const cookieModal = useRef<HTMLDivElement>(null);

  const router = useRouter();

  // If you need dark mode on another path, just add a pipe | and check the route.
  const darkMode = router.asPath.startsWith('/course/');

  const registerSheetIntent = (type: SheetIntent, payload: any) =>
    dispatch({ type: type, payload: payload });
  const closeSheet = () => dispatch({ type: 'CLOSE_SHEET' });

  const openCookieModal = useCallback(
    () => dispatch({ type: 'OPEN_COOKIE_MODAL' }),
    [],
  );
  const closeCookieModal = useCallback(
    () => dispatch({ type: 'CLOSE_COOKIE_MODAL' }),
    [],
  );

  // sets a css var named --vh that is a more accurate version of vh
  // for mobile browsersc
  useEffect(() => {
    const setDocHeight = () => {
      document.documentElement.style.setProperty(
        '--vh',
        `${window.innerHeight / 100}px`,
      );
    };

    window.addEventListener('resize', setDocHeight);
    window.addEventListener('orientationchange', setDocHeight);

    setDocHeight();

    return () => {
      window.removeEventListener('resize', setDocHeight);
      window.removeEventListener('orientationchange', setDocHeight);
    };
  }, []);

  return (
    <UiContext.Provider
      value={{
        state,
        dispatch,
        registerSheetIntent,
        closeSheet,
        openCookieModal,
        closeCookieModal,
        cookieModal,
      }}
    >
      <div className={darkMode ? 'dark' : undefined}>{children}</div>
    </UiContext.Provider>
  );
};

/** This is a hook you may use to access our context in a component. */
export const useUI = () => {
  const ctx = useContext(UiContext);
  if (!ctx) {
    throw new Error('useUI can only be used in the context of a UiProvider.');
  }
  return ctx;
};

import { AccordionProvider } from './AccordionContext';
import { AccordionItemProps } from './AccordionItem';

/**
 * Props for <Accordion>.
 * Children should only be <AccordionItem>.
 */
type AccordionProps = {
  children:
    | React.ReactElement<AccordionItemProps>
    | Array<React.ReactElement<AccordionItemProps>>;
  onlyAllowOneItemOpen: boolean;
};

/**
 * Accordion
 * @param children One or more <AccordionItem /> components.
 * @returns An accordion with the given children.
 */
const Accordion: React.FC<AccordionProps> = ({
  children,
  onlyAllowOneItemOpen,
}) => {
  return (
    <AccordionProvider onlyAllowOneItemOpen={onlyAllowOneItemOpen}>
      <ul className="Accordion">{children}</ul>
    </AccordionProvider>
  );
};

export default Accordion;

import DownloadFile from '../svgs/downloadFile.svg';
import PointerFinger from '../svgs/pointerFinger.svg';
import Web from '../svgs/web.svg';
import Pencil from '../svgs/pencil.svg';
import Link from '../svgs/link.svg';
import Close from '../svgs/close.svg';
import Menu from '../svgs/menu.svg';
import ChevronDown from '../svgs/chevronDown.svg';
import File from '../svgs/file.svg';
import Eye from '../svgs/eye.svg';
import GreenThumbsUp from '../svgs/greenThumbsUp.svg';
import ThumbsUp from '../svgs/thumbsUp.svg';
import Heart from '../svgs/heart.svg';
import Star1 from '../svgs/star1.svg';
import Star2 from '../svgs/star2.svg';
import Cube from '../svgs/cube.svg';
import Spring from '../svgs/spring.svg';
import Bounce from '../svgs/bounce.svg';
import Confused from '../svgs/confused.svg';
import Fern from '../svgs/fern.svg';
import Flower from '../svgs/flower.svg';
import Video from '../svgs/video.svg';
import Camera from '../svgs/camera.svg';
import Birthday from '../svgs/birthday.svg';
import Smile from '../svgs/smile.svg';
import Pronouns from '../svgs/pronouns.svg';

export default {
  downloadFile: DownloadFile,
  pointerFinger: PointerFinger,
  web: Web,
  pencil: Pencil,
  link: Link,
  close: Close,
  menu: Menu,
  chevronDown: ChevronDown,
  file: File,
  eye: Eye,
  greenThumbsUp: GreenThumbsUp,
  thumbsUp: ThumbsUp,
  heart: Heart,
  star1: Star1,
  star2: Star2,
  cube: Cube,
  spring: Spring,
  bounce: Bounce,
  confused: Confused,
  fern: Fern,
  flower: Flower,
  video: Video,
  camera: Camera,
  birthday: Birthday,
  smile: Smile,
  pronouns: Pronouns,
};

import Icon from 'elements/Icon';
import { PublicUser } from 'lib/api/types';
import getDigitFromChar from 'utils/getDigitFromChar';
import { IconType } from 'elements/types';
import { Digit } from 'types';

const digitToAvatarIconMap = new Map<Digit, IconType>([
  [0, 'spring'],
  [1, 'bounce'],
  [2, 'confused'],
  [3, 'fern'],
  [4, 'star1'],
  [5, 'star2'],
  [6, 'heart'],
  [7, 'cube'],
  [8, 'spring'],
  [9, 'flower'],
]);

const digitToColorsMap = new Map<Digit, string[]>([
  [0, ['bg-yellow', 'text-black']],
  [1, ['bg-neon', 'text-black']],
  [2, ['bg-green', 'text-black']],
  [3, ['bg-tomato', 'text-black']],
  [4, ['bg-med-blue', 'text-white']],
  [5, ['bg-light-blue', 'text-black']],
  [6, ['bg-fuscia', 'text-black']],
  [7, ['bg-orange', 'text-black']],
  [8, ['bg-indigo', 'text-white']],
  [9, ['bg-med-blue', 'text-white']],
]);

export const Avatar: React.FC<PublicUser> = (user) => {
  const { id } = user;

  const avatarDigit = getDigitFromChar(id[id.length - 1]);
  const colorDigit = getDigitFromChar(id[id.length - 2]);

  const avatarIcon = digitToAvatarIconMap.get(avatarDigit);

  let bgColor = 'bg-med-blue';
  let textColor = 'text-white';
  const colors = digitToColorsMap.get(colorDigit);
  if (colors) {
    bgColor = colors[0];
    textColor = colors[1];
  }

  return (
    <div
      className={`grid place-items-center w-full h-full aspect-square rounded-50% ${bgColor} ${textColor}`}
    >
      <Icon
        icon={avatarIcon as IconType}
        className="w-60% h-60%"
        size="custom"
      />
    </div>
  );
};

import React, { FC, useState } from 'react';
import cx from 'classnames';

import getAspectRatio from 'utils/getAspectRatio';

import { ImageDimensions, ImageCrop } from 'lib/cms/types';

type Props = {
  alt: string;
  isBg?: boolean;
  src: string;
  srcSet?: string;
  sizes?: string;
  className?: string;
  containerClassName?: string;
  preloadClassName?: string;
  children?: React.ReactNode;
  style?: React.CSSProperties;
  onLoad?: () => any;
  dimensions?: ImageDimensions;
  crop?: ImageCrop;
  useOriginalImageSize?: boolean;
};

const Img: FC<Props> = ({
  alt,
  isBg = false,
  src,
  srcSet,
  sizes,
  className = 'w100',
  containerClassName = '',
  preloadClassName,
  children = null,
  style,
  onLoad,
  dimensions,
  crop,
  useOriginalImageSize,
}) => {
  const [isLoaded, setIsLoaded] = useState(false);
  const onImageLoad = () => {
    if (onLoad) onLoad();
    setIsLoaded(true);
  };

  const bgStyle = {
    backgroundColor: '#b2ada8',
    backgroundImage: `url(${src})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    ...(style ? style : {}),
  };

  const classes = cx('Img transition-long', className, {
    'Img--active': isLoaded || isBg,
  });

  if (isBg) {
    return (
      <div className={classes} style={bgStyle}>
        {children}
      </div>
    );
  }

  if (!srcSet && !sizes) {
    return (
      <img
        onLoad={onImageLoad}
        className={classes}
        style={style}
        src={src}
        alt={alt}
      />
    );
  }

  return (
    <div className={cx(containerClassName, 'relative')}>
      {dimensions && (
        <div
          className={cx(classes, preloadClassName, 'events-none')}
          style={{ paddingTop: `${getAspectRatio(dimensions, crop)}%` }}
        />
      )}
      <picture>
        <img
          className={cx(classes, {
            'absolute t0 r0 l0 w100': dimensions,
          })}
          onLoad={onImageLoad}
          style={style}
          src={src}
          srcSet={srcSet}
          sizes={sizes}
          alt={alt}
        />
      </picture>
      {children}
    </div>
  );
};

export default Img;

import { createSlice, createAsyncThunk, createAction } from '@reduxjs/toolkit';
import { HYDRATE } from 'next-redux-wrapper';

import { ShopifyClient } from 'lib/shopify';
import { FetchProductArgs, Product } from 'lib/shopify/types';
import { AppState } from 'state/store';
import { RootState } from 'state/reducers';

const hydrate = createAction(HYDRATE);

export const fetchProduct = createAsyncThunk<
  Product,
  FetchProductArgs,
  { dispatch: any; state: RootState }
>('shopify/fetchProduct', (arg: FetchProductArgs, { getState }) => {
  const { shopify } = getState();
  const product = shopify.products[arg.handle];
  if (product) return product;
  return ShopifyClient.fetchProduct(arg);
});

type ShopifyState = {
  products: { [handle: string]: Product };
};

const initialState: ShopifyState = {
  products: {},
};

const shopifySlice = createSlice({
  name: 'shopify',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchProduct.fulfilled, (state, { meta, payload }) => {
      state.products[meta.arg.handle] = payload;
    });

    builder.addCase(hydrate, (state, action) => {
      const { shopify } = action.payload as AppState;
      return {
        ...state,
        products: {
          ...state.products,
          ...shopify.products,
        },
      };
    });
  },
});

export default shopifySlice.reducer;

import { createSlice, createAsyncThunk, createAction } from '@reduxjs/toolkit';
import { HYDRATE } from 'next-redux-wrapper';

import { CmsClient } from 'lib/cms';
import { Course, FetchCourseArgs } from 'lib/cms/types';
import { AppState } from 'state/store';
import { RootState } from 'state/reducers';

const hydrate = createAction(HYDRATE);

export const fetchCourse = createAsyncThunk<
  Course,
  FetchCourseArgs,
  { dispatch: any; state: RootState }
>('cms/fetchCourse', (arg: FetchCourseArgs, { getState }) => {
  const { cms } = getState();
  const course = cms.courses[arg.slug];
  if (course) return course;
  return CmsClient.fetchCourse(arg);
});

type CmsState = {
  courses: { [slug: string]: Course };
};

const initialState: CmsState = {
  courses: {},
};

const cmsSlice = createSlice({
  name: 'cms',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchCourse.fulfilled, (state, { meta, payload }) => {
      state.courses[meta.arg.slug] = payload;
    });

    builder.addCase(hydrate, (state, action) => {
      const { cms } = action.payload as AppState;

      return {
        ...state,
        courses: {
          ...state.courses,
          ...cms.courses,
        },
      };
    });
  },
});

export default cmsSlice.reducer;

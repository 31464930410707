import { GridItem } from 'components/Product';
import { SheetContainer } from 'components/SheetContainer';
import Language from 'constants/Language';
import {
  Accordion,
  AccordionItem,
  AccordionItemBody,
  AccordionItemHeader,
} from 'components/Accordion';
import { Course, Resource } from 'lib/cms/types';
import { Product } from 'lib/shopify/types';
import { MenuAccordion } from 'components/Resource/Menu';

interface Props {
  course: Course;
}

export const ResourceMenuSheet: React.FC<Props> = ({ course }) => {
  return (
    <>
      <SheetContainer.Section>
        <p className="font-serif text-serif-40px mb-3">{course.title}</p>
        <div className="flex items-center">
          {/* TODO: Replace with real link */}
          <img
            src="https://salon-production.nyc3.cdn.digitaloceanspaces.com/sk6bknvs1afnxmiyccrp815foxz3"
            alt=""
            className="w-10 h-10 rounded-xs overflow-hidden mr-3"
          />
          <div>
            <p className="uppercase font-sans-serif text-sans-serif-12px mb-1">
              Instructor
            </p>
            <p className="uppercase font-sans-serif text-sans-serif-16px">
              {/* TODO: Replace with data from Sanity or Bookings */}
              Megumi Tanaka
            </p>
          </div>
        </div>
      </SheetContainer.Section>
      <SheetContainer.Section bottomBorder={false}>
        <p className="uppercase font-sans-serif text-sans-serif-xxs mb-6">
          {Language.resource.menu.title}
        </p>
        <MenuAccordion course={course} />
      </SheetContainer.Section>
    </>
  );
};

import React, { useEffect } from 'react';
import { useAccordion } from './AccordionContext';
import { AccordionItemBodyProps } from './AccordionItemBody';
import { AccordionItemHeaderProps } from './AccordionItemHeader';

/**
 * Props for <AccordionItem>.
 * Children may only be <AccordionItemHeader> and <AccordionItemBody>.
 */
export type AccordionItemProps = {
  index: number;
  activeClassName?: string;
  preExpanded?: boolean;
  children:
    | React.ReactElement<AccordionItemHeaderProps | AccordionItemBodyProps>
    | Array<
        React.ReactElement<AccordionItemHeaderProps | AccordionItemBodyProps>
      >;
};

/**
 * Accordion -> AccordionItem
 * @param index A number to identify the item.
 * @param preExpanded A boolean to check if the item should be expanded on render.
 * @param activeClassName A string to set the className of the accordion when it is open.
 * @param children One or more <AccordionItem> components.
 * @returns All children with all props spread to the component.
 */
const AccordionItem: React.FC<AccordionItemProps> = ({
  index,
  preExpanded,
  activeClassName = '',
  children,
}) => {
  const { checkIfItemOpen, openAccordionItem } = useAccordion();

  const isOpen = checkIfItemOpen(index);

  useEffect(() => {
    if (preExpanded) {
      openAccordionItem(index);
    }
  }, []);

  return (
    <li className={`AccordionItem mb-3 ${isOpen ? activeClassName : ''}`}>
      {React.Children.map(children, (child: React.ReactElement) => {
        return React.cloneElement(child, {
          isOpen: isOpen,
          index,
        });
      })}
    </li>
  );
};

export default AccordionItem;

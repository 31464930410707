import React, { createContext, useContext, useState } from 'react';

export const AccordionContext = createContext<{
  checkIfItemOpen: (index: number) => boolean;
  openAccordionItem: (index: number) => void;
  closeAccordionItem: (index: number) => void;
  toggleAccordionItem: (index: number) => void;
}>({
  checkIfItemOpen: () => false,
  openAccordionItem: () => null,
  closeAccordionItem: () => null,
  toggleAccordionItem: () => null,
});

interface AccordionProviderProps {
  children: React.ReactNode;
  onlyAllowOneItemOpen: boolean;
}

export const AccordionProvider: React.FC<AccordionProviderProps> = ({
  children,
  onlyAllowOneItemOpen,
}) => {
  const [openItems, setOpenItems] = useState<number[]>([]);

  const openAccordionItem = (index: number) => {
    if (onlyAllowOneItemOpen) {
      setOpenItems([index]);
    } else {
      setOpenItems((prev) => [...prev, index]);
    }
  };

  const closeAccordionItem = (index: number) => {
    setOpenItems((prev) => {
      return prev.filter((item) => item !== index);
    });
  };

  const toggleAccordionItem = (index: number) =>
    openItems.includes(index)
      ? closeAccordionItem(index)
      : openAccordionItem(index);

  const checkIfItemOpen = (index: number) => openItems.includes(index);

  return (
    <AccordionContext.Provider
      value={{
        checkIfItemOpen,
        openAccordionItem,
        toggleAccordionItem,
        closeAccordionItem,
      }}
    >
      {children}
    </AccordionContext.Provider>
  );
};

/** This is a hook you may use to access our context in a component. */
export const useAccordion = () => {
  const ctx = useContext(AccordionContext);
  if (!ctx) {
    throw new Error(
      'useAccordion() can only be used in the context of an <AccordionContext> wrapper.',
    );
  }
  return ctx;
};

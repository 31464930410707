import Language from 'constants/Language';
import Link from 'next/link';
import { useRef } from 'react';
import getRandomInt from 'utils/getRandomInt';
import linkIsExternal from 'utils/linkIsExternal';

type GlobalNavButtonProps = {
  path: string;
  href: string;
  isLogo?: boolean;
  label: string;
};

const GlobalNavButton: React.FC<GlobalNavButtonProps> = ({
  path,
  href,
  isLogo = false,
  label,
}) => {
  const pathIsActive = path === href;

  // Alphabet array, minus characters in 'Index'
  const alphabet = Language.global.navigation.logo.alphabet.split('');
  const indexText = Language.global.navigation.logo.index;

  const logoRef = useRef<HTMLAnchorElement>(null);

  const handleMouseEnter = () => {
    randomizeLogo();
  };

  const handleMouseLeave = () => {
    resetLogo();
  };

  // TODO: Check with Jake on how this should be used.
  // useEffect(() => {
  //   randomizeLogo();
  // });

  const randomizeLogo = () => {
    const randomCharIndex = getRandomInt(0, indexText.length - 1);
    const randomAlphabetIndex = getRandomInt(0, alphabet.length - 1);

    const indexArr = indexText.split('');
    indexArr[randomCharIndex] = alphabet[randomAlphabetIndex];

    if (logoRef.current) logoRef.current.innerText = indexArr.join('');
  };

  const resetLogo = () => {
    if (logoRef.current) logoRef.current.innerText = indexText;
  };

  if (linkIsExternal(href)) {
    return (
      <a
        href={href}
        target="_blank"
        rel="noopener noreferrer"
        className={`rounded-xs px-1 transition-colors duration-300 hover:bg-black dark:hover:bg-white hover:text-white dark:hover:text-black
        ${
          isLogo
            ? 'GlobalNav__logo lowercase font-logo mr-2'
            : 'mr-5 hidden md:flex pt-1 pb-0.5'
        }
        ${
          pathIsActive &&
          'md:bg-black md:text-white md:dark:bg-white md:dark:text-black'
        }`}
        ref={isLogo ? logoRef : undefined}
        onMouseEnter={isLogo ? handleMouseEnter : undefined}
        onMouseLeave={isLogo ? handleMouseLeave : undefined}
      >
        {label}
      </a>
    );
  } else {
    return (
      <Link href={href}>
        <a
          className={`rounded-xs px-1 transition-colors duration-300 hover:bg-black dark:hover:bg-white hover:text-white dark:hover:text-black
        ${
          isLogo
            ? 'GlobalNav__logo lowercase font-logo mr-2'
            : 'mr-5 hidden md:flex pt-1 pb-0.5'
        }
        ${
          pathIsActive &&
          'md:bg-black md:text-white md:dark:bg-white md:dark:text-black'
        }`}
          ref={isLogo ? logoRef : undefined}
          onMouseEnter={isLogo ? handleMouseEnter : undefined}
          onMouseLeave={isLogo ? handleMouseLeave : undefined}
        >
          {label}
        </a>
      </Link>
    );
  }
};

export default GlobalNavButton;

import Icons from 'elements/Icons';
import * as z from 'zod';

export type PortableText = z.TypeOf<typeof PortableText>;
export const PortableText = z
  .object({
    _key: z.string(),
    _type: z.string(),
    type: z.string().optional(),
    level: z.number().optional(),
    listItem: z.string().optional(),
    markDefs: z.any().array(),
    style: z.string(),
    children: z.array(
      z
        .object({
          _key: z.string(),
          _type: z.string(),
          marks: z.any().array(),
          text: z.string(),
        })
        .partial(),
    ),
    node: z.object({ style: z.string() }).passthrough().optional(),
    mark: z.object({ link: z.string().optional() }).passthrough().optional(),
  })
  .passthrough();

export type ImageCrop = z.infer<typeof ImageCrop>;
export const ImageCrop = z.object({
  _type: z.string(),
  bottom: z.union([z.number(), z.undefined()]),
  left: z.union([z.number(), z.undefined()]),
  top: z.union([z.number(), z.undefined()]),
  right: z.union([z.number(), z.undefined()]),
});

export type Divider = z.TypeOf<typeof Divider>;
export const Divider = z.object({
  _type: z.literal('divider'),
  _key: z.string(),
  marginBottom: z.number().default(50),
  marginTop: z.number().default(50),
});

export type ImageDimensions = z.infer<typeof ImageDimensions>;
export const ImageDimensions = z.object({
  _type: z.string(),
  width: z.number(),
  height: z.number(),
  aspectRatio: z.number(),
});

export type Image = z.TypeOf<typeof Image>;
export const Image = z.object({
  id: z.string(),
  src: z.string(),
  alt: z.string().optional(),
  crop: ImageCrop.optional(),
  hotspot: z.any().optional(),
});

export type ImageWithCaption = z.TypeOf<typeof ImageWithCaption>;
export const ImageWithCaption = Image.extend({
  caption: z.array(PortableText).optional(),
});

export type RichTextBlock = z.TypeOf<typeof RichTextBlock>;
export const RichTextBlock = z.union([PortableText, Image]);

export type RichText = z.TypeOf<typeof RichText>;
export const RichText = z.array(RichTextBlock);

//Blocks
export type BlockBase = z.TypeOf<typeof BlockBase>;
export const BlockBase = z.object({
  _key: z.string(),
});

export type SerializeBlock = z.TypeOf<typeof SerializeBlock>;
export const SerializeBlock = z
  .object({
    key: z.string(),
    node: z.any().optional(),
    children: z.array(z.any()).optional(),
  })
  .passthrough();

export type CalloutBlock = z.TypeOf<typeof CalloutBlock>;
export const CalloutBlock = BlockBase.extend({
  _type: z.literal('calloutBlock'),
  blocks: z.array(RichText),
});

export type Vimeo = z.TypeOf<typeof Vimeo>;
export const Vimeo = z.object({
  type: z.string(),
  vimeoId: z.string(),
  vimeoAltText: z.string().optional(),
});

export type Youtube = z.TypeOf<typeof Youtube>;
export const Youtube = z.object({
  type: z.string(),
  youtubeId: z.string(),
  youtubeAltText: z.string().optional(),
});

export type Video = z.TypeOf<typeof Video>;
export const Video = z.object({
  type: z.string(),
  src: z.string(),
  alt: z.string(),
});

export type Spotify = z.TypeOf<typeof Spotify>;
export const Spotify = z.object({
  type: z.string(),
  src: z.string(),
  alt: z.string(),
});

export type EmbedMedia = z.TypeOf<typeof EmbedMedia>;
export const EmbedMedia = z.object({
  id: z.string(),
  _type: z.literal('embedBlock'),
  url: z.string().optional(),
  title: z.string().optional(),
  html: z.string().optional(),
  size: z.enum(['large', 'medium', 'small']).optional(),
  caption: z.string().optional(),
  attribution: z.string().optional(),
});

export type Media = z.TypeOf<typeof Media>;
export const Media = z.union([
  z.object({
    type: z.literal('video'),
    video: Video,
  }),
  z.object({
    type: z.literal('spotifyField'),
    spotify: Spotify,
  }),
  z.object({
    type: z.literal('vimeoField'),
    vimeo: Vimeo,
  }),
  z.object({
    type: z.literal('youtubeField'),
    youtube: Youtube,
  }),
  z.object({
    type: z.literal('embedField'),
    embed: EmbedMedia,
  }),
]);

export type MediaBlock = z.TypeOf<typeof MediaBlock>;
export const MediaBlock = BlockBase.extend({
  _type: z.literal('mediaBlock'),
  title: z.string().optional(),
  media: Media,
});

export type InstructorBlockModule = z.TypeOf<typeof InstructorBlockModule>;
export const InstructorBlockModule = BlockBase.extend({
  _type: z.literal('instructorBlock'),
  _key: z.string(),
  title: z.string().optional(),
  handle: z.string(),
});

export type ContentRow = z.TypeOf<typeof ContentRow>;
export const ContentRow = z.object({
  icon: z.string().optional(),
  eyebrow: z.string().optional(),
  listTitle: z.string(),
  description: z.any().optional(),
  link: z.string().url().optional(),
  metadata: z
    .object({
      shouldShowMetadata: z.boolean().default(false),
      title: z.string().optional(),
      eyebrow: z.string().optional(),
    })
    .optional(),
});

export type ContentRowBlock = z.TypeOf<typeof ContentRowBlock>;
export const ContentRowBlock = BlockBase.extend({
  _type: z.literal('contentRowBlock'),
  _key: z.string(),
  title: z.string(),
  description: z.string().optional(),
  content: z.array(ContentRow).optional(),
});

export type CodeBlock = z.TypeOf<typeof CodeBlock>;
export const CodeBlock = BlockBase.extend({
  _type: z.literal('codeBlock'),
  code: z
    .object({
      _type: z.literal('code'),
      language: z.string(),
      filename: z.string(),
      code: z.string(),
    })
    .passthrough(),
});

export type QuoteBlock = z.TypeOf<typeof QuoteBlock>;
export const QuoteBlock = BlockBase.extend({
  _type: z.literal('quoteBlock'),
  _key: z.string(),
  quoteName: z.string(),
  quoteLink: z.string().url().optional(),
  quote: z.string(),
});

export type MediaCarouselBlock = z.TypeOf<typeof MediaCarouselBlock>;
export const MediaCarouselBlock = BlockBase.extend({
  type: z.literal('mediaCarouselBlock'),
  title: z.string(),
  description: PortableText.optional(),
  slides: z.array(
    z.object({
      key: z.string(),
      image: Image,
      title: z.string().optional(),
      description: z.string().optional(),
      eyebrow: z.string().optional(),
    }),
  ),
});

export type FullImageBlock = z.TypeOf<typeof FullImageBlock>;
export const FullImageBlock = BlockBase.extend({
  _type: z.literal('fullImageBlock'),
  _key: z.string(),
  title: z.string().optional(),
  image: ImageWithCaption,
  alignment: z.enum(['center', 'fullBleed']).optional(),
});

export type CoursePageModule = z.TypeOf<typeof CoursePageModule>;
export const CoursePageModule = z.union([
  ContentRowBlock,
  CodeBlock,
  CalloutBlock,
  InstructorBlockModule,
  QuoteBlock,
  MediaCarouselBlock,
  FullImageBlock,
  MediaBlock,
  z.any(),
]);

//End of Blocks

export type Body = z.TypeOf<typeof Body>;
export const Body = z.array(CoursePageModule);

export type FetchResourceArgs = z.TypeOf<typeof FetchResourceArgs>;
export const FetchResourceArgs = z.object({
  slug: z.string(),
});

export type Resource = z.TypeOf<typeof Resource>;
export const Resource = z.any();

export type Product = z.TypeOf<typeof Product>;
export const Product = z
  .object({
    _createdAt: z.string(),
    _id: z.string(),
    _rev: z.string(),
    _type: z.literal('product').or(z.literal('reference')),
    _updatedAt: z.string(),
    store: z
      .object({
        createdAt: z.string(),
        id: z.number(),
        isDeleted: z.boolean(),
        options: z.any(),
        previewImageUrl: z.string().url(),
        priceRange: z.any(),
        productType: z.string(),
        slug: z.any(),
        status: z.string(),
        tags: z.any(),
        title: z.string(),
        updatedAt: z.string(),
        variants: z.array(z.any()),
      })
      .passthrough(),
  })
  .passthrough();

// A course record, without the lessons or products.
export type BaseCourse = z.TypeOf<typeof BaseCourse>;
export const BaseCourse = z
  .object({
    _id: z.string(),
    title: z.string(),
    label: z.string().optional(),
    introduction: z.array(z.any()).optional(),
    slug: z.object({
      _type: z.literal('slug'),
      current: z.string(),
    }),
    syllabus: Body,
  })
  .passthrough();

export type Lesson = z.TypeOf<typeof Lesson>;
export const Lesson = z
  .object({
    _createdAt: z.string(),
    _id: z.string(),
    _rev: z.string(),
    _type: z.string(),
    _updatedAt: z.string(),
    body: Body,
    introduction: z.array(z.any()),
    isSynchronous: z.boolean().optional().default(false),
    start: z.string().optional(),
    end: z.string().optional(),
    conference: z.string().optional(),
    slug: z.object({ _type: z.literal('slug'), current: z.string() }),
    title: z.string(),
    recording: z.string().optional(),
  })
  .passthrough()
  .refine(
    (data) =>
      !data.isSynchronous ? true : data.start && data.end && data.conference,
    {
      message: 'Synchronous lesson must have start, end and conference link.',
    },
  );

export type Course = z.TypeOf<typeof Course>;
export const Course = BaseCourse.extend({
  lessons: z.array(Lesson),
});

export type FetchCourseArgs = z.TypeOf<typeof FetchCourseArgs>;
export const FetchCourseArgs = z.object({
  slug: z.string(),
});

export type AllCourseVariantIds = z.TypeOf<typeof AllCourseVariantIds>;
export const AllCourseVariantIds = z.array(z.number());

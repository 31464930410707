import React, { FC } from 'react';
import Language from 'constants/Language';
import cx from 'classnames';

import Icons from 'elements/Icons';
import { IconType } from './types';

type Variation = 'active' | 'hover' | 'default';
type Size = 'small' | 'medium' | 'large' | 'default' | 'custom';

type Props = {
  icon: IconType;
  size?: Size;
  variation?: Variation;
  className?: string;
};

const getIconMarkup = (icon: IconType): React.ReactNode => {
  return React.createElement(Icons[icon], {
    title: Language.global.icons(icon).svgTitle,
    width: '100%',
    height: '100%',
  });
};

const Icon: FC<Props> = ({
  icon,
  size = 'default',
  variation,
  className = '',
}) => {
  const kebabCaseName = icon.replace(
    /[A-Z]/g,
    (letter) => `-${letter.toLowerCase()}`,
  );

  const sanitizedClassName = cx('Icon', `Icon--${kebabCaseName}`, className, {
    [`Icon--${kebabCaseName}--${variation}`]: !!variation,
    'w-5 h-5': size === 'small' || size === 'default',
    'w-8 h-8': size === 'medium',
    'w-16 h-16': size === 'large',
  });
  return <div className={sanitizedClassName}>{getIconMarkup(icon)}</div>;
};

export default Icon;

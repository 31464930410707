import Sticky from 'react-stickynode';
import { ChangeEvent } from 'react';
import { ProfileData, PublicUser, User } from 'lib/api/types';
import { CustomerOverview } from 'lib/shopify/types';
import { Order, OrderLineItemEdge } from 'shopify-storefront-api-typings';
import { Course } from 'lib/cms/types';

export enum HttpMethod {
  CONNECT = 'CONNECT',
  DELETE = 'DELETE',
  GET = 'GET',
  HEAD = 'HEAD',
  OPTIONS = 'OPTIONS',
  PATCH = 'PATCH',
  POST = 'POST',
  PUT = 'PUT',
  TRACE = 'TRACE',
}

export type SheetIntent =
  | 'SHOW_ORDER_DETAILS'
  | 'SHOW_TABLE_OF_CONTENTS'
  | 'CLOSE_SHEET';

export type SheetActionType = {
  type: SheetIntent;
  payload?: Course | Order | any;
};

export type SheetState = {
  intent: SheetIntent | null;
  data: Course | Order | any;
};

export type CookieModalIntent = 'CLOSE_COOKIE_MODAL' | 'OPEN_COOKIE_MODAL';

export type CookieModalActionType = {
  type: CookieModalIntent;
};

export type CookieModalState = {
  isOpen: boolean;
};

export type Maybe<T> = T | null | undefined;

export type StickyStatus = {
  status:
    | typeof Sticky.STATUS_ORIGINAL
    | typeof Sticky.STATUS_RELEASED
    | typeof Sticky.STATUS_FIXED;
};

export type ProfileField = {
  user: PublicUser | User;
  values?: {
    id: string;
    handle: string;
    data: ProfileData;
  };
  handleChange?: (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => void;
  displayInputs?: boolean;
};

export type ProfilePageContent = {
  profile: PublicUser;
  overview: CustomerOverview;
  stats: { name: string; count: number }[];
  orders?: Order[];
  purchasedCourses?: OrderLineItemEdge[];
};

export type DatePartsType = typeof DateParts;
export const DateParts = {
  weekday: '',
  month: '',
  day: '',
  hour: '',
  minute: '',
  dayPeriod: '',
};

export type Session = {
  isSynchronous: boolean;
  start: string;
  end: string;
  conference: string;
};

export type LessonType = 'live' | 'upcoming' | 'async';

export type Digit = 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9;

import { FetchCourseArgs } from 'lib/cms/types';

export const image = `{
  'type': _type,
  'id': asset->_id,
  'src': asset->url,
  alt,
  hotspot,
  crop,
}`;

const imageWithCaption = `{
  'type': _type,
  'id': asset->_id,
  'src': asset->url,
  alt,
  hotspot,
  crop,
  caption,
}`;

const contentRow = `{
  'key': _key,
  eyebrow,
  listTitle,
  icon,
  description,
  link,
  metadata
}`;

const video = `{
  'type':_type,
  'src':asset->url,
  'alt':videoAlt,
}`;

const vimeo = `{
  'type':_type,
  vimeoId,
  vimeoAltText
}`;

const youtube = `{
  'type':_type,
  youtubeId,
  youtubeAltText
}`;

const embedOther = `{
  'type':_type,
  id,
  url,
  title,
  html,
  size,
  caption,
  attribution,
}`;

const variant = `{
  'type': _type,
  'id': _id,
  'shopifyId': store.id,
  'productId': store.productId,
  'title': store.title,
  'price': store.price,
  'compareAtPrice': store.compareAtPrice,
  'option1': store.option1,
  'option2': store.option2,
  'option3': store.option3,
  'sku': store.sku,
}`;

const resource = `{
  'type': _type,
  name,
  role,
  avatar ${image}
}`;

const product = `{
  'type': _type,
  'id': _id,
  'shopifyId': store.id,
  'slug': store.slug,
  'title': store.title,
  'productType': store.productType,
  'status': store.status,
  'tags': store.tags,
  'previewImageUrl': store.previewImageUrl,
  'priceRange': store.priceRange,
  'options': store.options,
  'variants': store.variants[]-> ${variant},
  lower(store.productType) == 'event' || lower(store.productType) == 'course' || lower(store.productType) == 'workshop' => {
    'resource': resource ${resource}
  },
  vendor,
  featuredProductText,
}`;

const productWithVariant = `{
  'product': product->${product},
  'variant': variant->${variant}
}
`;

const MarkDefsGroq = `{
  ...,
  _type == "annotationLinkInternal" => {
    "slug": @.reference->slug,
    "pageType": @.reference->_type
  },
  _type == "annotationProduct" => {
    ...,
    "productWithVariant": @.productWithVariant ${productWithVariant}
  }
}`;

const BasicPortableTextGroq = `{
  ...,
  markDefs[] ${MarkDefsGroq}
}`;

const BodyPortableTextGroq = `{
  ...,
  _type == 'blockImage' => {
    _key,
    _type,
    caption,
    fullWidth,
    'image': image${image}
  },
  _type == 'blockProduct' => {
    _key,
    _type,
    caption,
    'productWithVariant': productWithVariant${productWithVariant}
  },
  _type == 'contentRowBlock' => {
    _key,
    _type,
    title,
    description,
    content[]${contentRow}
  },
  _type == 'instructorBlock' => {
    _key,
    _type,
    title,
    handle
  },
  _type == 'mediaCarouselBlock' => {
    _key,
    _type,
    title,
    'description': description${BasicPortableTextGroq},
    slides[]{
      'key': _key,
      title,
      description,
      eyebrow,
      'image':image${image},
    },
  },
  _type == 'quoteBlock' => {
    _key,
    _type,
    quoteName,
    quoteLink,
    quote[] ${BasicPortableTextGroq}
  },
  _type == 'codeBlock' => {
    _key,
    _type,
    code,
  },
  _type == 'calloutBlock' => {
    _key,
    blocks[] ${BasicPortableTextGroq}
  },
  _type == 'fullImageBlock' => {
    _key,
    _type,
    title,
    alignment,
    'image': image${imageWithCaption},
  },
  _type == 'mediaBlock' => {
    _key,
    _type,
    'media': media[0]{
      'type': _type,
      _type == 'video' => {
        'video': ${video},
      },
      _type == "vimeoField" => {
        'type':_type,
       'vimeo':${vimeo},
      },
      _type == "youtubeField" => {
        'type':_type,
       'youtube':${youtube},
      },
      _type == "embedField" => {
        'type':_type,
        'embed':${embedOther},
      },
    }
  },
  markDefs[] ${MarkDefsGroq}
}`;

const lesson = `{
  'type': _type,
  'id': _id,
  title,
  slug,
  introduction[] ${BasicPortableTextGroq},
  body[] ${BodyPortableTextGroq},
  ...,
}`;

export default {
  course: ({ slug }: FetchCourseArgs) => {
    let groq = `{
      _id,
      title,
      introduction[] ${BasicPortableTextGroq},
      label,
      lessons[]-> ${lesson},
      product->,
      slug,
      syllabus[] ${BodyPortableTextGroq}
    }`;

    return `*[_type == 'course' && slug.current == '${slug}'][0]${groq}`;
  },
  allCourseProductIds: () => {
    return `*[_type == 'course' && product->.store.id != null] {
      'id': product->.store.id
    }.id`;
  },
};

const toggleTabIndex = (
  elementRef: React.RefObject<HTMLElement>,
  isTabbable: boolean,
): void => {
  if (elementRef && elementRef.current) {
    const allButtons: NodeListOf<HTMLElement> =
      elementRef.current.querySelectorAll(
        'button, a, input, link, select, textarea',
      );
    allButtons.forEach((el) => {
      if (isTabbable) el.tabIndex = 0;
      else el.tabIndex = -1;
    });
  }
};

export default toggleTabIndex;

import React, { MouseEvent, RefObject } from 'react';
import Link from 'next/link';
import { string } from 'fp-ts';

import cx from 'classnames';
import linkIsExternal from 'utils/linkIsExternal';

type Props = {
  text?: string;
  leftIcon?: React.ReactNode;
  rightIcon?: React.ReactNode;
  ariaLabel: string;
  type: 'link' | 'button' | 'submit' | 'reset';
  size: 'inherit' | 'sm' | 'md' | 'lg';
  variant?: 'default' | 'outlined' | 'ghost' | 'nostyle';
  to?: string;
  openInNewTab?: boolean;
  openInCurrentTab?: boolean;
  disabled?: boolean;
  onClick?: () => void;
  onMouseEnter?(e: MouseEvent<HTMLElement>): void;
  onMouseLeave?(e: MouseEvent<HTMLElement>): void;
  elemRef?: RefObject<
    HTMLAnchorElement | HTMLAnchorElement | HTMLButtonElement
  >;
  className?: string;
};

const Button: React.FC<Props> = ({
  text,
  leftIcon,
  rightIcon,
  ariaLabel,
  type,
  size,
  variant = 'default',
  to = '',
  openInNewTab = false,
  openInCurrentTab = false,
  disabled,
  onClick,
  onMouseEnter,
  onMouseLeave,
  elemRef,
  className = '',
}) => {
  const buttonContent = (
    <>
      {!!leftIcon && leftIcon}
      {!!text && (
        <span
          className={cx({
            'ml-2': !!leftIcon,
            'mr-2': !!rightIcon,
          })}
        >
          {text}
        </span>
      )}
      {!!rightIcon && rightIcon}
    </>
  );

  const style = cx(className, {
    //type
    'font-sans-serif uppercase':
      type === 'button' || type === 'submit' || type === 'reset',
    'rounded-xs': variant !== 'nostyle',
    underline: type === 'link',
    //size
    'text-sans-serif-50px py-2 px-6': size === 'lg',
    'text-sans-serif-30px uppercase py-2 px-6': size === 'md',
    'text-sans-serif-12px pt-1.5 pb-1.25 px-1.25 tracking-wide': size === 'sm',
    //variants
    'bg-black text-white border border-black': variant === 'default',
    'bg-white text-black border border-black': variant === 'outlined',
    'bg-white text-black': variant === 'ghost',
    //disabled
    'opacity-50': !!disabled,
    //icon and text exists
    'flex items-center': (!!leftIcon || !!rightIcon) && !!text,
  });

  const linkIsMailOrTel = to.includes('mailto:') || to.includes('tel:');

  const linkedComponent =
    linkIsMailOrTel || linkIsExternal(to) || openInNewTab ? (
      <a
        className={style}
        target={openInCurrentTab || linkIsMailOrTel ? '_self' : '_blank'}
        href={to}
        rel="noreferrer"
        onClick={onClick}
        aria-label={ariaLabel}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        ref={elemRef as RefObject<HTMLAnchorElement>}
      >
        {buttonContent}
      </a>
    ) : (
      <Link href={to} passHref={true}>
        <a className={style} onClick={onClick} href={to} aria-label={ariaLabel}>
          {buttonContent}
        </a>
      </Link>
    );

  const button = !!to ? (
    linkedComponent
  ) : (
    <button
      aria-label={ariaLabel}
      className={style}
      onClick={onClick}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      ref={elemRef as RefObject<HTMLButtonElement>}
      type={type !== 'link' ? type : 'button'}
      disabled={!!disabled}
    >
      {buttonContent}
    </button>
  );
  return button;
};

export default Button;

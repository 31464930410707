import * as z from 'zod';

export type FetchProductArgs = z.TypeOf<typeof FetchProductArgs>;
export const FetchProductArgs = z.object({
  handle: z.string(),
});

export type Product = z.TypeOf<typeof Product>;
export const Product = z
  .object({
    id: z.string(),
    title: z.string(),
    descriptionHtml: z.string(),
  })
  .passthrough();

export type CustomerOverview = {
  displayName: string;
  firstName: string;
  lastName: string;
  createdAt: string;
  id: string;
};

import Language from 'constants/Language';
import { useUI } from 'contexts/Ui';
import React, { FC } from 'react';
import { FocusOn } from 'react-focus-on';
import { SheetState } from 'types';
import { OrderDetailsSheet } from './Sheets/OrderDetailsSheet';
import { ResourceMenuSheet } from './Sheets/ResourceMenuSheet';
import Icon from '../elements/Icon';
import Button from '../elements/Button';

type SheetSectionProps = {
  children: React.ReactNode;
  fullBleed?: boolean;
  className?: string;
  bottomBorder?: boolean;
};

const Section: React.FC<SheetSectionProps> = ({
  children,
  fullBleed,
  className,
  bottomBorder = true,
}) => (
  <section
    className={`font-light ${!fullBleed && 'py-5 px-3'} ${
      bottomBorder && 'border-b border-black'
    } ${className}`}
  >
    {children}
  </section>
);

const getSheetForIntent = (sheet: SheetState): React.ReactNode => {
  switch (sheet.intent) {
    case 'SHOW_ORDER_DETAILS':
      return <OrderDetailsSheet />;
    case 'SHOW_TABLE_OF_CONTENTS':
      return <ResourceMenuSheet course={sheet.data} />;
    default:
      return null;
  }
};

const SheetContainer: FC = () => {
  const {
    state: { sheet },
    closeSheet,
  } = useUI();

  const sheetForIntent = getSheetForIntent(sheet);

  const isOpen = sheet.intent !== null;

  return (
    <FocusOn
      enabled={!!sheet.intent}
      onEscapeKey={closeSheet}
      onClickOutside={closeSheet}
    >
      <>
        {isOpen && (
          <div
            className={'fixed z-40 w-full h-full left-0 top-0 duration-400'}
            onClick={closeSheet}
            aria-hidden={!isOpen}
          />
        )}
        <aside
          className={`border-l border-black top-0 transition-transform transform duration-400 z-9999 max-w-full max-h-full overflow-y-scroll bg-white fixed h-screen right-0 w-full sm:w-1/2 lg:w-1/3 ${
            isOpen ? 'translate-x-0' : 'translate-x-full'
          }`}
          aria-hidden={!isOpen}
        >
          <Button
            className="w-full cursor-pointer pb-2 pt-3 uppercase text-right flex items-center justify-end border-b rounded-none border-black text-sans-serif-42px sm:text-sans-serif-30px xl:text-sans-serif-42px font-light px-3 h-16 leading-none sticky top-0 bg-white"
            onClick={closeSheet}
            leftIcon={
              <Icon icon="smile" className="w-12 h-12 -mt-1" size="custom" />
            }
            text={Language.sheets.container.close}
            type="button"
            variant="nostyle"
            ariaLabel={Language.sheets.container.close}
            size="inherit"
          />
          {sheetForIntent}
        </aside>
      </>
    </FocusOn>
  );
};

const SheetContainerNamespace = Object.assign(SheetContainer, {
  Section: Section,
});

export { SheetContainerNamespace as SheetContainer };

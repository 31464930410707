import React from 'react';
import Sticky from 'react-stickynode';
import Link from 'next/link';
import {
  Accordion,
  AccordionItem,
  AccordionItemHeader,
  AccordionItemBody,
} from 'components/Accordion';
import { StickyStatus } from 'types';
import Language from 'constants/Language';
import Button from 'elements/Button';
import Icon from 'elements/Icon';
import { useUI } from 'contexts/Ui';
import { Course } from 'lib/cms/types';
import { useRouter } from 'next/router';

interface Props {
  course: Course;
  isLesson?: boolean;
}

export const MenuAccordion: React.FC<Props> = ({
  course,
  isLesson = false,
}) => {
  const router = useRouter();

  return (
    <Accordion onlyAllowOneItemOpen={true}>
      <>
        <AccordionItem index={0} aria-hidden={true} preExpanded={isLesson}>
          <AccordionItemHeader>
            <p>{Language.course.syllabus}</p>
          </AccordionItemHeader>
          <AccordionItemBody>
            <ul className="uppercase">
              <li>
                <Link
                  href={{
                    pathname: '/course/[courseSlug]',
                    query: {
                      courseSlug: router.query.courseSlug,
                    },
                  }}
                  passHref
                >
                  <a
                    className={`inline-block font-sans-serif text-sans-serif-16px uppercase border-current p-1 rounded-xs mb-1 ${
                      !router.query.lesson && 'border'
                    }`}
                  >
                    {/* TODO: Replace with headings from body */}
                    test
                  </a>
                </Link>
              </li>
            </ul>
          </AccordionItemBody>
        </AccordionItem>

        {course.lessons.map((session, i) => (
          <AccordionItem
            key={session._id}
            preExpanded={router.query.lesson === session.slug.current}
            index={i + 1}
            aria-hidden={true}
          >
            <AccordionItemHeader>
              <p className="mr-3">{i + 1}.</p>
              <p>{session.title}</p>
            </AccordionItemHeader>
            <AccordionItemBody>
              <ul className="uppercase">
                {/* TODO: Replace with array of headings from Sanity */}
                {[
                  'Overview',
                  'Food for Thought',
                  'Theory',
                  'Workshop',
                  'Homework',
                  'Readings',
                ].map((item, i) => (
                  <li key={i}>
                    <Link
                      href={{
                        pathname: '/course/[courseSlug]',
                        query: {
                          lesson: session.slug.current,
                          courseSlug: router.query.courseSlug,
                        },
                      }}
                      passHref
                    >
                      <a
                        className={`inline-block font-sans-serif text-sans-serif-16px uppercase border-current p-1 rounded-xs mb-1 ${
                          router.query.lesson === session.slug.current &&
                          'border'
                        }`}
                      >
                        {item}
                      </a>
                    </Link>
                  </li>
                ))}
              </ul>
            </AccordionItemBody>
          </AccordionItem>
        ))}
      </>
    </Accordion>
  );
};

const Menu: React.FC<Props> = ({ course }) => {
  const { dispatch } = useUI();

  const openMobileMenu = () => {
    dispatch({
      type: 'SHOW_TABLE_OF_CONTENTS',
      payload: course,
    });
  };

  return (
    <Sticky
      // Activate sticky position after scrolling the height of <GlobalNav>
      top=".GlobalNav"
      innerClass="text-white lg:filter lg:invert lg:mix-blend-difference z-40"
    >
      {({ status }: StickyStatus) => (
        <nav
          title={Language.resource.menu.title}
          className={`Menu fixed md:pl-site-md w-full lg:w-1/3 lg:pr-4 lg:top-4`}
        >
          <div className="bg-black h-12 text-white border-b p-3 uppercase sticky top-16 z-10 whitespace-nowrap overflow-x-auto no-scrollbar flex lg:hidden items-center w-full justify-between">
            <p className="text-white font-sans-serif text-sans-serif-16px">
              Contents
            </p>
            <Button
              type="button"
              size="inherit"
              variant="nostyle"
              leftIcon={<Icon icon="menu" />}
              ariaLabel={
                Language.global.navigation.mobileMenu.controls.open.ariaLabel
              }
              className="GlobalNav__Icon--Open lg:pointer-events-none lg:hidden transition-long bg-color-transparent w-7 h-7 relative text-black dark:text-white mr-site-xs"
              onClick={openMobileMenu}
            />
          </div>
          <div
            className={`hidden lg:block w-full transition-opacity duration-300 bg-black lg:bg-transparent lg:opacity-100 h-auto pointer-events-auto`}
          >
            <p className="uppercase font-sans-serif text-sans-serif-12px mb-3">
              {Language.resource.menu.title}
            </p>
            <MenuAccordion course={course} />
          </div>
        </nav>
      )}
    </Sticky>
  );
};

export default Menu;

import Button from 'elements/Button';
import { GridItem } from 'components/Product';
import { SheetContainer } from 'components/SheetContainer';
import Language from 'constants/Language';
import { useUI } from 'contexts/Ui';
import { Order, OrderLineItemEdge } from 'shopify-storefront-api-typings';

export const OrderDetailsSheet: React.FC = () => {
  const {
    state: {
      sheet: { data },
    },
  } = useUI();

  const order: Order = data;

  if (!order) return null;

  return (
    <>
      <SheetContainer.Section>
        <p className="text-serif-40px font-serif">
          {Language.sheets.orderDetails.orderNumber({
            number: order.orderNumber,
          })}
        </p>
        <p className="text-sans-serif-16px font-sans-serif">
          {Language.sheets.orderDetails.orderedDate({
            date: new Date(order.processedAt).toLocaleDateString('en-US'),
          })}
        </p>
      </SheetContainer.Section>
      <SheetContainer.Section>
        <p className="font-serif text-serif-40px mb-2">
          {Language.sheets.orderDetails.shippingInformation.title}
        </p>
        {!!order.shippingAddress && (
          <address className="font-sans-serif text-sans-serif-16px not-italic mb-2 leading-relaxed">
            {order.shippingAddress.formatted.map((line: string) => (
              <p key={line}>{line}</p>
            ))}
          </address>
        )}
        <Button
          size="sm"
          to={order.statusUrl}
          openInNewTab={true}
          text={
            Language.sheets.orderDetails.shippingInformation.trackPackage.text
          }
          type="button"
          ariaLabel={
            Language.sheets.orderDetails.shippingInformation.trackPackage
              .ariaLabel
          }
          variant="outlined"
        />
      </SheetContainer.Section>
      <SheetContainer.Section>
        <p className="font-serif text-serif-40px mb-2">
          {Language.sheets.orderDetails.billing.title}
        </p>
        <div className="flex justify-between font-sans-serif text-sans-serif-12px uppercase mb-2">
          <p>{Language.sheets.orderDetails.billing.subtotal}</p>
          <p>{`${order.totalPriceV2.currencyCode} ${order.totalPriceV2.amount}`}</p>
        </div>
        {!!order.totalTaxV2 && (
          <div className="flex justify-between text-sans-serif-12px uppercase mb-2">
            <p>{Language.sheets.orderDetails.billing.tax}</p>
            <p>{`${order.totalTaxV2.currencyCode} ${order.totalTaxV2.amount}`}</p>
          </div>
        )}
        <div className="flex justify-between font-sans-serif text-sans-serif-12px uppercase mb-2">
          <p>{Language.sheets.orderDetails.billing.shipping}</p>
          <p>{`${order.totalShippingPriceV2.currencyCode} ${order.totalShippingPriceV2.amount}`}</p>
        </div>
        <div className="flex justify-between font-sans-serif text-sans-serif-12px uppercase mb-2">
          <p>{Language.sheets.orderDetails.billing.total}</p>
          <p className="font-medium">
            {`${order.totalPriceV2.currencyCode} ${order.totalPriceV2.amount}`}
          </p>
        </div>
      </SheetContainer.Section>
      {order.lineItems.edges.some((e) => !!e.node.variant) && (
        <SheetContainer.Section fullBleed className="pt-5">
          <p className="font-serif text-serif-40px mb-2 px-3">
            {Language.sheets.orderDetails.items.title}
          </p>
          <div className="grid grid-cols-1">
            {order.lineItems.edges.map(({ node }: OrderLineItemEdge) => (
              <GridItem key={node.title} product={node} />
            ))}
          </div>
        </SheetContainer.Section>
      )}
    </>
  );
};

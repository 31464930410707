import React, { useEffect, MouseEvent, useCallback } from 'react';
import localForage from 'localforage';
import { differenceInDays } from 'date-fns';
import { useUI } from 'contexts/Ui';
import Img from 'components/Img';

import cx from 'classnames';
import Language from 'constants/Language';

const CookieModal: React.FC = () => {
  const duration = 30;
  const {
    state: {
      cookieModal: { isOpen },
    },
    openCookieModal,
    closeCookieModal,
    cookieModal,
  } = useUI();

  const handleCookieModal = useCallback(
    (durationInDays: number) => {
      localForage.getItem('cookieModalDismissDate', (err, value) => {
        if (!value) {
          openCookieModal();
        }
        if (err) {
          console.error(err);
        }

        const diffInDays = differenceInDays(
          new Date(),
          new Date(value as string),
        );

        if (diffInDays >= durationInDays) {
          openCookieModal();
        }
      });
    },
    [openCookieModal],
  );

  useEffect(() => {
    handleCookieModal(duration);
  }, [duration, handleCookieModal]);

  const handleButtonClick = (evt: MouseEvent<HTMLButtonElement>) => {
    evt.preventDefault();
    closeCookieModal();
  };

  return (
    <div
      ref={cookieModal}
      className={cx(
        'CookieModal transition-transform z-20 fixed right-2.5 bottom-2.5',
        {
          'pointer-events-auto opacity-1': isOpen,
          'pointer-events-none opacity-0': !isOpen,
        },
      )}
    >
      <button
        role="button"
        aria-label={Language.global.cookieModal.button.ariaLabel}
        onClick={handleButtonClick}
      >
        <Img
          src={Language.global.cookieModal.src}
          alt={Language.global.cookieModal.button.ariaLabel}
        />
      </button>
    </div>
  );
};

export default CookieModal;

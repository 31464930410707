import { combineReducers } from 'redux';

import cmsReducer from 'state/slices/cms';
import shopifyReducer from 'state/slices/shopify';

const rootReducer = combineReducers({
  cms: cmsReducer,
  shopify: shopifyReducer,
});

export default rootReducer;
export type RootState = ReturnType<typeof rootReducer>;

import AuthTokens from 'lib/authTokens';
import Request from 'lib/request';
import { HttpMethod } from 'types';
import * as z from 'zod';

import * as ApiTypes from './types';

const Api = {
  url: (path: string) =>
    `https://${process.env.INDEX_API_BASE_HOST}/api${path}`,
  headers: async () => {
    const base: {
      Accept: string;
      'Content-Type': string;
      'X-Shopify-Domain': string | undefined;
      'X-Shopify-Storefront-Access-Token': string | undefined;
      'X-Shopify-Customer-Access-Token'?: string;
    } = {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'X-Shopify-Domain': process.env.SHOPIFY_DOMAIN,
      'X-Shopify-Storefront-Access-Token':
        process.env.SHOPIFY_STOREFRONT_ACCESS_TOKEN,
    };
    const authToken = await AuthTokens.get();
    if (authToken !== null) {
      base['X-Shopify-Customer-Access-Token'] = authToken;
    }
    return base;
  },
  async request<TResponse extends z.ZodTypeAny>(options: {
    method: HttpMethod;
    path: string;
    body?: object;
    params?: object;
  }): Promise<z.TypeOf<TResponse>> {
    return Request.make({
      method: options.method,
      url: Api.url(options.path),
      body: options.body,
      params: options.params,
      headers: (await Api.headers()) as Record<string, string>,
    });
  },
};

export const Tokens = {
  create(email: string, password: string): Promise<ApiTypes.Token> {
    return Api.request({
      method: HttpMethod.POST,
      path: `/users/tokens`,
      body: {
        email,
        password,
      },
    }).then((response) => Request.parse(ApiTypes.Token, response));
  },
};

export const Users = {
  create(user: {
    email: string;
    password: string;
    handle: string;
    data: ApiTypes.ProfileData;
    activation_id?: string;
    activation_token?: string;
  }): Promise<ApiTypes.User> {
    let body: { [key: string]: any } = {
      email: user.email,
      password: user.password,
      handle: user.handle,
      data: user.data,
    };

    if (!!user.activation_id && !!user.activation_token) {
      body = {
        ...body,
        activation_id: user.activation_id,
        activation_token: user.activation_token,
      };
    }

    return Api.request({
      method: HttpMethod.POST,
      path: `/users`,
      body: body,
    }).then((response) => Request.parse(ApiTypes.User, response));
  },

  update(user: {
    email: string;
    handle: string;
    data: ApiTypes.ProfileData;
  }): Promise<ApiTypes.User> {
    return Api.request({
      method: HttpMethod.POST,
      path: `/users/update`,
      body: {
        email: user.email,
        handle: user.handle,
        data: user.data,
      },
    }).then((response) => Request.parse(ApiTypes.User, response));
  },

  exists(email: string): Promise<ApiTypes.Exists> {
    return Api.request({
      method: HttpMethod.GET,
      path: `/users/exists`,
      params: {
        email,
      },
    })
      .then((response) => Request.parse(ApiTypes.Exists, response))
      .catch((error) => {
        if ('exists' in error) return error;
        throw error;
      });
  },

  current(): Promise<ApiTypes.User> {
    return AuthTokens.get().then((authToken) => {
      if (!authToken) return Promise.resolve(<ApiTypes.User>{});
      return Api.request({
        method: HttpMethod.GET,
        path: `/users/current`,
      }).then((response) => Request.parse(ApiTypes.User, response));
    });
  },

  get(handle: string): Promise<ApiTypes.PublicUser> {
    return Api.request({
      method: HttpMethod.GET,
      path: `/users/${handle}`,
    }).then((response) => Request.parse(ApiTypes.PublicUser, response));
  },
};

export const Passwords = {
  reset(email: string): Promise<null> {
    return Api.request({
      method: HttpMethod.POST,
      path: `/passwords`,
      body: {
        email,
      },
    }).then((response) => Request.parse(z.null(), response));
  },

  update(
    customerId: string,
    newPassword: string,
    resetToken: string,
  ): Promise<ApiTypes.Token> {
    return Api.request({
      method: HttpMethod.PATCH,
      path: `/passwords/${customerId}`,
      body: {
        new_password: newPassword,
        reset_token: resetToken,
      },
    }).then((response) => Request.parse(ApiTypes.Token, response));
  },
};

export { User, Token, ExistsPlatforms, Exists, ServerError } from './types';

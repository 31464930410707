import { gql } from 'graphql-request';

export const orderHistoryQuery = gql`
  query getCustomerOrderHistory($customerAccessToken: String!) {
    customer(customerAccessToken: $customerAccessToken) {
      id
      orders(first: 250) {
        edges {
          node {
            id
            fulfillmentStatus
            processedAt
            name
            orderNumber
            statusUrl
            subtotalPriceV2 {
              amount
              currencyCode
            }
            totalTaxV2 {
              amount
              currencyCode
            }
            totalShippingPriceV2 {
              amount
              currencyCode
            }
            totalPriceV2 {
              amount
              currencyCode
            }
            shippingAddress {
              name
              formatted(withName: true)
            }
            lineItems(first: 250) {
              edges {
                node {
                  title
                  quantity
                  originalTotalPrice {
                    amount
                    currencyCode
                  }
                  variant {
                    id
                    image {
                      originalSrc
                    }
                    product {
                      id
                      productType
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

export const customerOverviewQuery = gql`
  query getCustomerOverview($customerAccessToken: String!) {
    customer(customerAccessToken: $customerAccessToken) {
      displayName
      createdAt
      firstName
      id
      lastName
    }
  }
`;

import { useRouter } from 'next/router';
import { useEffect, useState, useRef } from 'react';
import { useAuth } from 'contexts/Auth';
import Language from 'constants/Language';
import Link from 'next/link';
import Icon from 'elements/Icon';
import { Avatar } from 'components/Profile/Avatar';
import Button from 'elements/Button';
import GlobalNavButton from './GlobalNavButton';
import { FocusOn } from 'react-focus-on';
import toggleTabIndex from 'utils/toggleTabIndex';

type GlobalNavProps = {
  showBottomBorder?: boolean;
  isHidden: boolean;
};

const GlobalNav: React.FC<GlobalNavProps> = ({
  showBottomBorder = true,
  isHidden,
}) => {
  const router = useRouter();
  const links = Language.global.navigation.links;

  const { user } = useAuth();

  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  const openMobileMenu = () => setIsMobileMenuOpen(true);
  const closeMobileMenu = () => setIsMobileMenuOpen(false);

  const mobileMenuWrapper = useRef<HTMLDivElement>(null);

  useEffect(() => {
    toggleTabIndex(mobileMenuWrapper, isMobileMenuOpen);
  }, [isMobileMenuOpen]);

  const renderMobileMenuControls = () => {
    if (!isMobileMenuOpen) {
      return (
        <Button
          size="inherit"
          type="button"
          variant="nostyle"
          leftIcon={<Icon icon="menu" />}
          ariaLabel={
            Language.global.navigation.mobileMenu.controls.open.ariaLabel
          }
          className="md:pointer-events-none md:hidden transition-long bg-color-transparent w-7 h-7 relative text-black dark:text-white"
          onClick={openMobileMenu}
        />
      );
    } else {
      return (
        <Button
          leftIcon={<Icon icon="close" />}
          size="inherit"
          type="button"
          variant="nostyle"
          ariaLabel={
            Language.global.navigation.mobileMenu.controls.close.ariaLabel
          }
          className="md:pointer-events-none md:hidden transition events-none bg-color-transparent w-7 h-7 relative text-black dark:text-white"
          onClick={closeMobileMenu}
        />
      );
    }
  };

  return (
    <FocusOn enabled={isMobileMenuOpen} onEscapeKey={closeMobileMenu}>
      <nav
        title={Language.global.navigation.title}
        role="navigation"
        className={`
        GlobalNav fixed z-50 top-0 right-0 left-0 h-16 px-site-xs md:px-site-md
        uppercase font-light font-sans-serif text-sans-serif-30px xl:text-sans-serif-42px
        bg-white text-black dark:bg-black dark:text-white
        transition-colors easy-ease-out duration-1000
        ${showBottomBorder && 'border-b'}
        ${isHidden && 'hidden'}
      `}
      >
        <div className="relative flex w-full items-center justify-between py-5 h-full">
          <div className="flex flex-row items-center justify-center">
            <GlobalNavButton
              isLogo={true}
              path={router.asPath}
              href="/"
              label={Language.global.navigation.logo.index}
            />
            <span className="ml-1 mr-2.5 hidden md:block">—</span>
            {renderMobileMenuControls()}
            {links.map((link) => {
              return (
                <GlobalNavButton
                  path={router.asPath}
                  href={link.href}
                  label={link.text}
                  key={link.href}
                />
              );
            })}
          </div>
          {user && (
            <div
              className="w-12 h-12 relative"
              title={Language.global.navigation.profileLink.ariaLabel}
            >
              <Link href={`/profile/${user.handle}`}>
                <a
                  aria-label={Language.global.navigation.profileLink.ariaLabel}
                >
                  <Avatar {...user} />
                </a>
              </Link>
            </div>
          )}
          <div
            className={`flex flex-col md:hidden md:pointer-events-none h-screen w-full fixed top-16 left-0 right-0 pt-3 px-3 z-10 duration-300 bg-white dark:bg-black ${
              isMobileMenuOpen
                ? 'opacity-100 pointer-events-auto'
                : 'opacity-0 pointer-events-none'
            }`}
            ref={mobileMenuWrapper}
            aria-hidden={!isMobileMenuOpen}
          >
            <div className="flex flex-col pt-7">
              {links.map((link, i) => (
                <Link href={link.href} key={link.href}>
                  <a
                    className={`text-sans-serif-30px uppercase mb-7 opacity-0 text-black dark:text-white ${
                      isMobileMenuOpen
                        ? `animate-stepped-fade-in-and-down-short-${i + 1}`
                        : ''
                    }`}
                    aria-label={link.ariaLabel}
                  >
                    {link.text}
                  </a>
                </Link>
              ))}
            </div>
          </div>

          <div className="absolute -bottom-px -left-site-xs md:-left-site-md right-0 w-screen h-0.5">
            <div className="relative h-0.5" id="GlobalNav__Loader"></div>
          </div>
        </div>
      </nav>
    </FocusOn>
  );
};

export default GlobalNav;

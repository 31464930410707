import Language from 'constants/Language';
import React from 'react';
import EmailSubscribe from './EmailSubscribe';

function Footer() {
  return (
    <footer className="Footer clear-both w-full font-serif text-serif-40px lg:text-serif-75px px-5 pt-96 leading-tight bg-white">
      <p>
        <a
          className="Footer__button text-sans-serif-30px lg:text-sans-serif-65px inline-block leading-none rounded-xs md:rounded-sm font-sans-serif border-black border uppercase px-1 bg-white text-black hover:bg-black hover:text-white transition-colors duration-200"
          href={`mailto:${Language.global.footer.contact_links.email.emailAddress}`}
          target="_blank"
          rel="noreferrer noopener"
          aria-label={Language.global.footer.contact_links.email.ariaLabel(
            Language.global.footer.contact_links.email.emailAddress,
          )}
        >
          {Language.global.footer.contact_links.email.text}
        </a>
        {` if you’d like to teach a workshop, propose an event, feature a product
        or get involved in any other way. You can also `}
        <EmailSubscribe />
        {` and follow us on `}
        <a
          className="Footer__button text-sans-serif-30px lg:text-sans-serif-65px inline-block leading-none rounded-xs md:rounded-sm font-sans-serif border-black border uppercase px-1 bg-white text-black hover:bg-black hover:text-white transition-colors duration-200"
          href={Language.global.footer.contact_links.instagram.href}
          target="_blank"
          rel="noreferrer noopener"
          aria-label={Language.global.footer.contact_links.instagram.ariaLabel(
            Language.global.footer.contact_links.instagram.href,
          )}
        >
          {Language.global.footer.contact_links.instagram.text}
        </a>
        {' and '}
        <a
          className="Footer__button inline-block text-sans-serif-30px lg:text-sans-serif-65px leading-none rounded-xs md:rounded-sm font-sans-serif border-black border uppercase px-1 bg-white text-black hover:bg-black hover:text-white transition-colors duration-200"
          href={Language.global.footer.contact_links.twitter.href}
          target="_blank"
          rel="noreferrer noopener"
          aria-label={Language.global.footer.contact_links.twitter.ariaLabel(
            Language.global.footer.contact_links.twitter.href,
          )}
        >
          {Language.global.footer.contact_links.twitter.text}
        </a>
        {' to stay up to date.'}
      </p>
    </footer>
  );
}

export default Footer;

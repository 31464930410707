import React, { FC, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import type { AppProps } from 'next/app';
import { wrapper } from 'state/store';
import 'styles/index.scss';
import { UiProvider } from 'contexts/Ui';
import { SheetContainer } from 'components/SheetContainer';
import { AuthProvider } from 'contexts/Auth';
import Footer from 'components/Footer';
import GlobalNav from 'components/GlobalNav';
import { useRouter } from 'next/router';
import NextNProgress from 'nextjs-progressbar';
import CookieModal from 'components/CookieModal';

const App: FC<AppProps> = ({ Component, pageProps }) => {
  const router = useRouter();
  const dispatch = useDispatch();

  const showNavAndFooter =
    router.pathname !== '/auth' && !router.pathname.includes('/account/reset');

  useEffect(() => {
    console.log('App Boot');
  }, [dispatch]);

  return (
    <AuthProvider>
      <UiProvider>
        <NextNProgress
          color="#2dac61"
          showOnShallow={false}
          options={{
            easing: 'easy-ease-out',
            showSpinner: false,
            // The parent is located in GlobalNav
            parent: '#GlobalNav__Loader',
          }}
        />
        <GlobalNav showBottomBorder={true} isHidden={!showNavAndFooter} />
        <div className={showNavAndFooter ? 'pt-navbar-height' : ''}>
          <Component {...pageProps} />
          {showNavAndFooter ? <Footer /> : null}
        </div>
        <CookieModal />
        <SheetContainer />
      </UiProvider>
    </AuthProvider>
  );
};

export default wrapper.withRedux(App);

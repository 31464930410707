import Language from 'constants/Language';
import jsonp from 'jsonp';
import React, { useEffect, useState } from 'react';
import * as Sentry from '@sentry/nextjs';

const SUBSCRIBE_URL =
  'https://nyc.us3.list-manage.com/subscribe/post-json?u=1a2ff75e095034fc6d0c19ef5&amp;id=64aa34c740';

type Status = 'default' | 'error' | 'submitting' | 'success';

type GlyphProps = {
  status: Status;
};

const Glyph: React.FC<GlyphProps> = ({ status }) => {
  switch (status) {
    case 'submitting':
      return (
        <>{Language.global.footer.newsletter_subscribe.glyph.submitting}</>
      );
    case 'success':
      return null;
    case 'error':
      return null;
    default:
      return <>{Language.global.footer.newsletter_subscribe.glyph.default}</>;
  }
};

const EmailSubscribe = () => {
  const [status, setStatus] = useState<Status>('default');

  const [value, setValue] = useState('');

  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue(event.target.value);
  };

  // Reset input state after timeout.
  useEffect(() => {
    if (status === 'error' || status === 'success') {
      const timer = setTimeout(() => resetSubscribeInput(), 3000);
      return () => {
        clearTimeout(timer);
      };
    }
  }, [status]);

  const resetSubscribeInput = () => {
    setStatus('default');
    setValue('');
  };

  const generateSubscribeUrl = (email: string) =>
    `${SUBSCRIBE_URL}&EMAIL=${email}`;

  const handleSubmit = () => {
    const subscribeUrl = generateSubscribeUrl(value);

    setStatus('submitting');

    jsonp(subscribeUrl, { param: 'c' }, (err, data) => {
      if (err) {
        setStatus('error');
        Sentry.captureMessage(data.msg);
      } else if (data.result !== 'success') {
        if (data.msg.includes('subscribed')) {
          setStatus('success');
          setValue('');
        } else {
          Sentry.captureMessage(data.msg);
          setStatus('error');
        }
      } else {
        setStatus('success');
        setValue('');
      }
    });
  };

  return (
    <span
      className={`EmailSubscribe relative inline-flex items-center font-sans-serif bg-black text-white rounded-xs md:rounded-sm overflow-hidden pr-3 h-10 lg:h-16 -top-3 min-w-full md:min-w-30 lg:min-w-45 xl:min-w-35 transition-colors ${
        status === 'error' && 'bg-red'
      } ${status === 'success' && 'bg-green'}`}
    >
      <input
        type="email"
        name="emailAddress"
        autoComplete="email"
        onKeyDown={(e) => {
          if (e.key === 'Enter') {
            handleSubmit();
          }
        }}
        value={value}
        onChange={onChange}
        disabled={
          status === 'submitting' || status === 'success' || status === 'error'
        }
        placeholder={
          status === 'success'
            ? Language.global.footer.newsletter_subscribe.placeholders.success
            : Language.global.footer.newsletter_subscribe.placeholders.default
        }
        className="EmailSubscribe block placeholder-white placeholder-opacity-50 flex-grow border-0 outline-none bg-transparent text-sans-serif-16px md:text-sans-serif-30px pl-3 h-full"
      />

      <span
        onClick={() => {
          if (status === 'default') {
            handleSubmit();
          }
        }}
        className={`inline-block align-middle items-center max-h-full font-serif text-white ${
          status === 'submitting' ? '-mt-6 md:-mt-10' : 'mt-0 md:mt-3'
        } ${status === 'default' ? 'cursor-pointer' : 'pointer-events-none'}`}
      >
        <Glyph status={status} />
      </span>
    </span>
  );
};

export default EmailSubscribe;

import { GraphQLClient } from 'graphql-request';
import Shopify from 'shopify-buy';
import * as Sentry from '@sentry/nextjs';
import z from 'zod';
import { customerOverviewQuery, orderHistoryQuery } from './graphql-queries';
import { FetchProductArgs, Product } from './types';

const client = Shopify.buildClient({
  domain: process.env.SHOPIFY_DOMAIN!,
  storefrontAccessToken: process.env.SHOPIFY_STOREFRONT_ACCESS_TOKEN!,
});

const graphQLClient = new GraphQLClient(
  `https://${process.env.SHOPIFY_DOMAIN}/api/graphql`,
  {
    headers: {
      'X-Shopify-Storefront-Access-Token':
        process.env.SHOPIFY_STOREFRONT_ACCESS_TOKEN!,
    },
  },
);

const handleShopifyResponse = (parser: z.ZodTypeAny) => (response: unknown) => {
  try {
    return parser.parse(response);
  } catch (err) {
    if (process.env.NODE_ENV === 'development') {
      console.error('Shopify response type mismatch', err, response);
    }
    if (process.env.NODE_ENV === 'production') {
      Sentry.captureException(err);
    }
    throw err;
  }
};

export const loadCustomerOrders = async (customerAccessToken: string) => {
  return graphQLClient.request(orderHistoryQuery, {
    customerAccessToken: customerAccessToken,
  });
};

export const loadCustomerOverview = async (customerAccessToken: string) => {
  return graphQLClient.request(customerOverviewQuery, {
    customerAccessToken: customerAccessToken,
  });
};

export const ShopifyClient: {
  fetchProduct(args: FetchProductArgs): Promise<Product>;
} = {
  fetchProduct: (args: FetchProductArgs) => {
    return client.product
      .fetchByHandle(args.handle)
      .then(handleShopifyResponse(Product));
  },
};

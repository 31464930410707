import React, { useEffect, useRef } from 'react';
import toggleTabIndex from 'utils/toggleTabIndex';

/**
 * Props for <AccordionItemBody>.
 * Props are automatically passed to the component from <AccordionItem>.
 */
export type AccordionItemBodyProps = {
  index?: number;
  activeClassName?: string;
  isOpen?: boolean;
  children: any;
};

/**
 * Accordion -> AccordionItem -> AccordionItemBody
 * @param index Passed automatically by <AccordionItem />.
 * @param activeClassName Passed manually. Sets the className of the body when active.
 * @param isOpen Passed automatically by <AccordionItem />.
 * @param children Any valid React children.
 * @returns The body of the accordion item.
 */
const AccordionItemBody: React.FC<AccordionItemBodyProps> = ({
  index,
  isOpen,
  activeClassName = '',
  children,
}) => {
  const contentEl = useRef<HTMLDivElement>(null);

  // if (contentEl.current === null) return 'loading';

  useEffect(() => {
    toggleTabIndex(contentEl, !!isOpen);
  }, [isOpen]);

  return (
    <div
      role="region"
      ref={contentEl}
      aria-hidden={!isOpen}
      aria-labelledby={`AccordionItemHeader-${index}`}
      id={`AccordionItemBody-${index}`}
      className={`AccordionItemBody h-0 overflow-hidden transition-all ease-easy-ease-out ml-8 ${
        isOpen && activeClassName
      }`}
      style={
        isOpen
          ? {
              height: contentEl?.current?.scrollHeight,
            }
          : { height: 0 }
      }
    >
      {children}
    </div>
  );
};

export default AccordionItemBody;

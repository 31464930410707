import React, { createContext, useEffect, useState, useContext } from 'react';
import AuthToken from 'lib/authTokens';
import { Users, Tokens, Passwords } from 'lib/api';
import * as ApiTypes from 'lib/api/types';
import { GetServerSidePropsContext } from 'next';

// Assign types to anything added to the context provider value prop.
// This enables type checking in context consumers.
type AuthContext = {
  testUserExists: (email: string) => Promise<ApiTypes.ExistsPlatforms>;
  signUpUser: (
    email: string,
    password: string,
    handle: string,
    data: ApiTypes.ProfileData,
    activation_id?: string,
    activation_token?: string,
  ) => Promise<ApiTypes.User | null>;
  signInUser: (
    email: string,
    password: string,
  ) => Promise<ApiTypes.User | null>;
  reloadCurrentUser: () => Promise<ApiTypes.User>;
  updateUser: (
    email: string,
    handle: string,
    data: ApiTypes.ProfileData,
  ) => Promise<ApiTypes.User>;
  signOutUser: () => Promise<boolean>;
  triggerPasswordReset: (email: string) => Promise<boolean>;
  updatePasswordAfterReset: (
    customerId: string,
    newPassword: string,
    resetToken: string,
  ) => void;
  loadUser: () => void;
  loadingUser: boolean;
  user: ApiTypes.User | null;
};

const AuthContext = createContext<AuthContext | null>(null);

const defaultUserValue = null;

/**
 * Use in getServerSideProps() to access Auth context,
 * user data, and auth token. Returns a tuple of user data and
 * Shopify Storefront access token.
 */
export const withAuth = async (
  context: GetServerSidePropsContext,
): Promise<[ApiTypes.User | null, string | null]> => {
  const token = await AuthToken.getAndStashFromContext(context);
  if (token) return [await Users.current(), token];
  return [defaultUserValue, null];
};

export const AuthProvider: React.FC = ({ children }) => {
  const [user, setUser] = useState<ApiTypes.User | null>(defaultUserValue);
  const [loadingUser, setLoadingUser] = useState<boolean>(true);

  const testUserExists = async (email: string) => {
    const response = await Users.exists(email);
    return response.exists;
  };

  const signUpUser: AuthContext['signUpUser'] = async (
    email,
    password,
    handle,
    data,
    activation_id,
    activation_token,
  ) => {
    setLoadingUser(true);
    if (!!activation_id && !!activation_token) {
      await Users.create({
        email,
        password,
        handle,
        data,
        activation_id,
        activation_token,
      });
    } else {
      await Users.create({ email, password, handle, data });
    }
    const user = await signInUser(email, password);
    return user;
  };

  const signInUser: AuthContext['signInUser'] = async (email, password) => {
    setLoadingUser(true);
    const response = await Tokens.create(email, password);
    if (response.token) {
      await AuthToken.set(response.token);
      const user = await Users.current();
      setUser(user);
      setLoadingUser(false);
      return user;
    }
    setLoadingUser(false);
    return defaultUserValue;
  };

  const reloadCurrentUser: AuthContext['reloadCurrentUser'] = async () => {
    const user = await Users.current();
    setUser(user);
    return user;
  };

  const updateUser: AuthContext['updateUser'] = async (email, handle, data) => {
    const user = await Users.update({ email, handle, data });
    setUser(user);
    return user;
  };

  const signOutUser: AuthContext['signOutUser'] = async () => {
    await AuthToken.clear();
    setUser(defaultUserValue);
    return true;
  };

  const triggerPasswordReset: AuthContext['triggerPasswordReset'] = async (
    email,
  ) => {
    await Passwords.reset(email);
    return true;
  };

  const updatePasswordAfterReset: AuthContext['updatePasswordAfterReset'] =
    async (customerId, newPassword, resetToken) => {
      return await Passwords.update(customerId, newPassword, resetToken);
    };

  const loadUser: AuthContext['loadUser'] = () => {
    const fetchData = async () => {
      setLoadingUser(true);

      const token = await AuthToken.get();

      if (token) setUser(await Users.current());

      setLoadingUser(false);
    };
    fetchData();
  };

  // Attempt to load user at runtime.
  useEffect(loadUser, []);

  return (
    <AuthContext.Provider
      value={{
        loadingUser,
        loadUser,
        user,
        testUserExists,
        signInUser,
        signUpUser,
        reloadCurrentUser,
        updateUser,
        signOutUser,
        triggerPasswordReset,
        updatePasswordAfterReset,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

/**
 * A React hook to expose context values to components.
 */
export const useAuth = () => {
  const auth = useContext(AuthContext);
  if (auth === null)
    throw 'useAuth must be used within the context of the AuthContext.Provider';
  return auth;
};
